import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoadingButtonModule} from "@widgets/loading-button/loading-button.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgxCaptchaModule} from "ngx-captcha";
import {IconModule} from "@visurel/iconify-angular";
import {TrustHtmlModule} from "@app/shared/trust-html/trust-html.module";
import {MatListModule} from "@angular/material/list";
import {LinkedinWidget} from "@widgets/linkedin-widget/linkedin.widget";
import {ComposeAiModule} from "@widgets/gpt3-ui/compose-ai/compose-ai.module";
import {FlexModule} from "@angular/flex-layout";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
  imports: [
    ComposeAiModule,
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    LoadingButtonModule,
    MatSnackBarModule,
    NgxCaptchaModule,
    IconModule,
    TrustHtmlModule,
    MatListModule,
    FormsModule,
    FlexModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule
  ],
  providers: [],
  declarations: [LinkedinWidget],
  exports: [LinkedinWidget]
})
export class LinkedinWidgetModule {
}
