import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {SubjectLineWidget} from "@widgets/subject-line-widget/subject-line.widget";
import {ReactiveFormsModule} from "@angular/forms";
import {LoadingButtonModule} from "@widgets/loading-button/loading-button.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgxCaptchaModule} from "ngx-captcha";
import {IconModule} from "@visurel/iconify-angular";
import {TrustHtmlModule} from "@app/shared/trust-html/trust-html.module";
import {FlexLayoutModule} from "@angular/flex-layout";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    LoadingButtonModule,
    MatSnackBarModule,
    NgxCaptchaModule,
    IconModule,
    TrustHtmlModule,
    FlexLayoutModule
  ],
  providers: [],
  declarations: [SubjectLineWidget],
  exports: [SubjectLineWidget]
})
export class SubjectLineWidgetModule { }
