import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from "@visurel/iconify-angular";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatSliderModule} from "@angular/material/slider";
import {MatExpansionModule} from "@angular/material/expansion";
import {ReactiveFormsModule} from "@angular/forms";
import {Gpt3SettingsComponent} from "./gpt3-settings.component";


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        IconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSliderModule,
        MatExpansionModule,
        ReactiveFormsModule,
    ],
  declarations: [
    Gpt3SettingsComponent,
  ],
  exports: [
    Gpt3SettingsComponent,
  ]
})
export class Gpt3SettingsModule {
}
