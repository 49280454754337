import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MultiWidget} from "@widgets/multi-widget/multi.widget";
import {FollowUpWidgetModule} from "@widgets/follow-up-widget/follow-up.widget.module";
import {SubjectLineWidgetModule} from "@widgets/subject-line-widget/subject-line.widget.module";
import {Gpt3SuggestMessageModule} from "@widgets/gpt3-ui/suggest-message/gpt3-suggest-message.module";
import {FlexLayoutModule} from "@angular/flex-layout";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FollowUpWidgetModule,
    SubjectLineWidgetModule,
    Gpt3SuggestMessageModule,
    FlexLayoutModule,
  ],
  providers: [],
  declarations: [MultiWidget],
  exports: [MultiWidget]
})
export class MultiWidgetModule { }
