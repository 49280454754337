import {
  AfterViewInit,
  Component, ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { PopoverRef, PopoverContent } from './popover-ref';
import {getValueByPath} from "@helper-functions/get-value-by-path";
import {DestroyObserver} from "@app/abstracts/destroy-observer";
import {tap} from "rxjs/operators";

@Component({
  template: `
    <div class="popover rounded-border">
      <ng-container [ngSwitch]="renderMethod">
        <div *ngSwitchCase="'text'" [innerHTML]="content"></div>
        <ng-container *ngSwitchCase="'template'">
          <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'component'">
          <ng-container #ref></ng-container>
        </ng-container>
      </ng-container>
    </div>
  `,
  styleUrls: ['./popover.component.css']
})
export class PopoverComponent extends DestroyObserver implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ref', { read: ViewContainerRef }) ref: ViewContainerRef;
  renderMethod: 'template' | 'component' | 'text' = 'component';
  content: any;
  context;

  constructor(private popoverRef: PopoverRef, private cfr: ComponentFactoryResolver) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.content = this.popoverRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef)
      }
    } else {
      // @ts-ignore
      const componentFactory = this.cfr.resolveComponentFactory(this.popoverRef.content);
      const ref = this.ref.createComponent(componentFactory);

      const inputs = getValueByPath(this.popoverRef, 'data.inputs');
      const outputs = getValueByPath(this.popoverRef, 'data.outputs');
      if(inputs) {
        for(let k in inputs) {
          ref.instance[k] = inputs[k];
        }
      }
      ref.instance.overlayRef = this.popoverRef.overlay;
      if(outputs) {
        for(let k in outputs) {
          if(ref.instance[k] && outputs[k]) {
            ref.instance[k].pipe(
              tap((v)=> outputs[k](v)),
            this.notifier$
            ).subscribe()
          }
        }
      }
      ref.changeDetectorRef.detectChanges();
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
