import {ComponentFactoryResolver, Injectable, RendererFactory2} from '@angular/core';
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {BehaviorSubject, fromEvent, Observable, of} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {MatSnackBar} from "@angular/material/snack-bar";
import {catchError, switchMap} from "rxjs/operators";
import {retryFindElements} from "@helper-functions/retry-find-element";
import {getPlatformByUrl} from "@helper-functions/is-provider";


@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  platform = getPlatformByUrl();
  bg = new BehaviorSubject('transparent');
  bg$ = this.bg.asObservable();
  fnEndpoint: string = 'https://writi-337101.uc.r.appspot.com'; // //'https://writi-337101.uc.r.appspot.com'; //'https://writi-337101.uc.r.appspot.com';//'http://localhost:3333';//'https://wirit-final.azurewebsites.net';//'https://us-central1-writi-337101.cloudfunctions.net'//;
  private renderer;
  constructor(private http: HttpClient,
              private rendererFactory: RendererFactory2,
              private snackBar: MatSnackBar,
              private afAuth: AngularFireAuth,
              private fns: AngularFireFunctions,
              private cfr: ComponentFactoryResolver) {
    // this.afAuth.useEmulator('http://localhost:5001')
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  async addClassToOverlay(className: string = 'writi-root', lookFor = '.mat-select-panel-wrap') {
    const elems = await retryFindElements(lookFor);
    if(elems && elems.length) {
      elems.forEach(elem => {
        this.renderer.addClass(elem, className);
      });
    }
  }

  scrollEvent() {
    return fromEvent(window, 'scroll')
  }

  setBg(bg) {
    this.bg.next(bg);
  }

  getFnUrl(fnName: string) {
    return `${this.fnEndpoint}/${fnName}`;
  }

  getHeaders() {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return headers;
  }

  notification(message: string, title: string = '', duration = 3000, classes = '') {
    const panelClass = ['writi-root', 'snack-success'];
    if(classes && classes.length > 1) {
      panelClass.push(classes);
    }
    return this.snackBar.open(message, title, {duration, panelClass});
  }

  createFnGetRequest(endpoint, headers = {}) {
    const url = this.getFnUrl(endpoint);
    return this.http.get(url,  { headers });
  }

  createFnPostRequest(fnName: string, body: any): Observable<any> {
    const headers = this.getHeaders();
    const url = this.getFnUrl(fnName);
    return this.http.post(url, body, { headers });
  }

  resolveComp(ref, component) {
    const componentFactory = this.cfr.resolveComponentFactory(component);
    return ref.createComponent(componentFactory);
  }

  functionRequest$(fnName, data): Observable<any> {
    const isFn = false;
    if(isFn) {
      console.log('functionRequest$', fnName, data);
      const callable = this.fns.httpsCallable(fnName);
      return callable(data).pipe(switchMap((response: any)=> {
          let resp = {error: true, ...response};
          if(response.error) {
            this.notification(response.message, 'ALERT');
          } else {
            resp = response;
          }
          console.log('callable-callable-callable-callable', fnName, data, response);
          return of(resp);
        }),
        catchError((response)=> {
          console.log('catchError', fnName, data);
          this.notification(response.message, 'ERROR');
          return of({error: true, catch: true, ...response})
        })
      );
    } else {
      return this.createFnPostRequest(fnName, data).pipe(switchMap((response: any)=> {
          let resp: any = {error: true};
          if(response.error) {
            resp.code = response.code;
            this.notification(response.message, 'ERROR');
          } else {
            resp = response;
          }
          return of(resp);
        }),
        catchError((response)=> {
          console.log('catchError', fnName, data);
          this.notification(response.message, 'ERROR');
          return of({error: true, catch: true})
        }));
    }
  }
}
