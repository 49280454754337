import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import emailIcon from '@iconify/icons-mdi-light/email';
import {take} from "rxjs/operators";
import {BehaviorSubject, of} from "rxjs";
import {AI_ACTION} from "@const/ai.enums";
import {TextAiService} from "@services/text-ai.service";
import {
  plainVariableToProsVariable,
} from "@helper-functions/message-helpers";
import {GeneralService} from "@services/general.service";
import logoutIcon from '@iconify/icons-mdi-light/logout';
import {AuthService} from "@services/auth.service";

@Component({
  selector: 'gpt3-suggest-message',
  template: `
    <div [style.max-height]="innerHeight" class="gpt3-suggest-message rounded-border">
      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!noHeader" class="bg-primary p-10">
        <div> New Message</div>
        <button (click)="logout()" mat-icon-button class="pull-r">
          <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" color="white" [icon]="logoutIcon"></ic-icon>
        </button>
      </div>
      <div class="p-10 suggets-panel gmail-menu-shadow">
        <div class="" [formGroup]="form">
          <div fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="0" fxLayout.xs="column" fxLayoutAlign="center center"
               fxLayoutAlign.xs="start start" class="settings-row">
            <div class="m-b-20 toolbar-input toolbar-input-border fc fr" *ngIf="types.length > 1">
              <mat-select (openedChange)="onOpen()" (focus)="onOpen()" panelClass="writi-root" style="min-width: 190px"
                          formControlName="type">
                <mat-option *ngFor="let type of types;" [value]="type.value">
                  {{type.label}}
                </mat-option>
              </mat-select>
            </div>

            <!--              <div style="white-space: nowrap;" class="m-b-20" *ngIf="types.length <= 1">{{selectedType}}</div>-->
            <ng-container *ngIf="form.value.type === 5">
              <div fxLayout="row" fxLayoutAlign="center center">
                <div class="m-r-5">With</div>
                <div class="m-r-5 toolbar-input toolbar-input-border fc fr">
                  <mat-select (openedChange)="onOpen()" (focus)="onOpen()" panelClass="writi-root"
                              formControlName="steps">
                    <mat-option *ngFor="let num of [1,2,3]" [value]="num">
                      {{num}} steps
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </ng-container>
            <div fxLayout="row" class="fw m-b-20" fxLayoutAlign="center center">
              <div class="m-r-20" style="white-space: nowrap;">
                Tone
              </div>
              <gpt3-tone-select [maxWidth]="null" class="fw" formControlName="tone"></gpt3-tone-select>
            </div>
            <!--              <div class="m-b-20" fxShow.xs="false">-->
            <!--                <button [disabled]="loading" class="" mat-stroked-button (click)="generate()">-->
            <!--                  <loading-button title="Generate" [loading]="loading"></loading-button>-->
            <!--                </button>-->
            <!--              </div>-->
          </div>

          <div class="m-b-20  fc fr" *ngIf="form.value.type === 2 || form.value.type === 3">
                <textarea class="fw textarea-editor toolbar-input toolbar-input-border"
                          formControlName="about"
                          matInput></textarea>
          </div>
          <!--            <mat-divider></mat-divider>-->
          <div class="fc m-t-10" *ngIf="form.value.type === 4">
            <form fxLayout="column" class="fw" [formGroup]="formInstructions">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="m-b-20">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center" class="fw">
                  <div class="field-name">Audience</div>
                  <div class="fw toolbar-input toolbar-input-border fc fr">
                    <mat-select (openedChange)="onOpen()" (focus)="onOpen()" formControlName="to">
                      <mat-option *ngFor="let t of to;" [value]="t">
                        {{t}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <div class="fw" *ngIf="form.value.instructions.to === 'Other (free text)'">
                  <input formControlName="toOther"
                         class="fw toolbar-input toolbar-input-border fc fr"/>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="center center" class="m-b-20">
                <div class="field-name m-r-5">
                  Action
                </div>
                <div class="fw toolbar-input toolbar-input-border fc fr">
                  <mat-select (openedChange)="onOpen()" (focus)="onOpen()" formControlName="messageType">
                    <mat-option *ngFor="let messageType of messageTypes;" [value]="messageType">
                      {{messageType}}
                    </mat-option>
                  </mat-select>
                </div>
              </div>

              <div fxLayout="column" class="fw m-b-20">
                <div class="fr m-b-10">
                  <div class="field-name m-r-5">
                    Description
                  </div>
                  <mat-slide-toggle formControlName="isBullets"
                                    class="pull-r">{{form.value.instructions.isBullets ? 'Bullet points' : 'Summary'}}</mat-slide-toggle>
                </div>
                <div class="fc fr fw">
                    <textarea bulletpoint-area
                              [activeBullets]="form.value.instructions.isBullets" formControlName="about" rows="5"
                              style="min-height: 90px;"
                              class="p-10 fw toolbar-input toolbar-input-border"
                              matInput></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div *ngIf="showGenerate" class="m-b-20 fw">
          <button [disabled]="loading" class="fw msg-generate-btn" mat-stroked-button (click)="generate()">
            <loading-button title="Generate" [loading]="loading"></loading-button>
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" class="m-t-10" *ngIf="!loading && messages.length">
          <mat-selection-list [style.min-width.px]="minWidth" [multiple]="false" style="width: 100%; max-width: 400px;">
            <mat-list-option class="fc fr message-suggestion" *ngFor="let msg of messages" [value]="msg"
                             (click)="selected(msg)">
              <div class="fc fr">
                <!--                  <ic-icon class="m-r-5" style="display: flex;align-items: center;font-size: 20px"-->
                <!--                           [icIcon]="emailIcon"></ic-icon>-->
                <span style="font-size: 14px;text-align: left !important;" [innerHtml]="msg | trustHtml"></span>
              </div>
            </mat-list-option>
          </mat-selection-list>
          <!--            <div *ngIf="showAdd" fxLayout="row" fxLayoutAlign="end end" class="fw m-t-10">-->
          <!--              <button [disabled]="loading" class="flex-push-right" mat-stroked-button (click)="add()">-->
          <!--                Add-->
          <!--              </button>-->
          <!--            </div>-->
        </div>
        <!--          <div *ngIf="loading" class="fw center-text">-->
        <!--            Loading...-->
        <!--          </div>-->
        <!--            <div class="full-width">-->
        <!--                <gpt3-settings formControlName="settings"></gpt3-settings>-->
        <!--            </div>-->
      </div>
    </div>
    <div *ngIf="showAdd && messages && messages.length" fxLayout="row" fxLayoutAlign="end end" class="fw m-t-10 p-15">
      <button [disabled]="loading" class="msg-add-btn flex-push-right" mat-stroked-button (click)="add()">
        Add
      </button>
    </div>
  `,
  styles: [`
    :host .toolbar-input {
      width: 100%;
      min-width: 120px;
      height: 35px;
    }

    .suggets-panel {
      background: white;
    }

    .message-suggestion {
      height: auto !important;
      margin-bottom: 5px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    .field-name {
      white-space: nowrap;
      min-width: 70px;
    }

    .preview-variable {
      display: inline-block !important;
      background: #4284f3;
      color: white;
      padding: 3px 7px;
      border-radius: 4px;
      box-sizing: border-box;
      max-width: 170px;
      font-size: 14px;
      text-align: center;
    }

    :host textarea {
      overflow: auto;
    }

    .gpt3-suggest-message {
      height: 100%;
      overflow: auto;
    }
  `],
})
export class Gpt3SuggestMessageComponent implements OnDestroy, OnInit, AfterViewInit {
  innerHeight = '100%';
  state: any = {};
  emailIcon = emailIcon;
  logoutIcon = logoutIcon;
  loading = false;
  selectedType = '';
  @Input() include = [AI_ACTION.INSTRUCTION];
  @Input() showAdd = true;
  @Input() showGenerate = true;
  @Input() noHeight = false;
  @Input() noHeader = false;
  @Input() minWidth = 350;
  @Input() message = '';
  @Input() cacheId = '';
  @Input() overlayRef;
  @Output() loadingState = new EventEmitter();
  @Output() passMessage = new EventEmitter();
  messages: any = [];
  formInstructions = new UntypedFormGroup({
    messageType: new UntypedFormControl(''),
    to: new UntypedFormControl(''),
    toOther: new UntypedFormControl(''),
    about: new UntypedFormControl(''),
    isBullets: new UntypedFormControl('')
  });
  form = new UntypedFormGroup({
    tone: new UntypedFormControl(''),
    settings: new UntypedFormControl({}),
    about: new UntypedFormControl(''),
    type: new UntypedFormControl(AI_ACTION.INSTRUCTION),
    steps: new UntypedFormControl(1),
    instructions: this.formInstructions
  })
  types = [];
  _types = [
    // {
    //     label: 'Rewrite email',
    //     value: AI_ACTION.REWRITE
    // },
    // {
    //     label: 'Paraphrase email',
    //     value: AI_ACTION.PARAPHRASE
    // },
    // {
    //     label: 'Email by bullet points',
    //     value: AI_ACTION.BULLET_POINT
    // },
    {
      label: 'Email by instructions',
      value: AI_ACTION.INSTRUCTION
    }
  ]

  to = ['Colleagues', 'Partners', 'Customers', 'Potential Customers', 'Other (free text)'];

  messageTypes = [
    'Promoting',
    'Announcing',
    'Recommending',
    'Reminding of',
    'Telling a story about',
    'Inquiring about',
    'Suggesting',
    'Reviewing',
    'Reporting about',
    'Offering',
    'Selling',
    'Whishing'
  ];

  constructor(private textAiService: TextAiService, private generalService: GeneralService, private authService: AuthService) {
  }

  onOpen() {
    this.generalService.addClassToOverlay('writi-root');
  }

  ngOnInit(): void {
    if (!this.noHeight) {
      this.innerHeight = (window.innerHeight - 80) + 'px';
    }
    this.form.valueChanges.subscribe((value) => {
      this.state.value = value;
      this.textAiService.setResults(this.cacheId, {value}, true);
    });
    if (this.cacheId) {
      const state = this.textAiService.getResults(this.cacheId);
      if (state) {
        if (state.messages) {
          this.messages = state.messages;
        }
        if (state.value) {
          this.form.patchValue(state.value);
        }
      }
    }
    if (this.include && this.include.length) {
      this.types = this._types.filter((t: any) => this.include.includes(t.value));
      const v = this.types && this.types[0] && this.types[0].value || AI_ACTION.INSTRUCTION;
      this.form.get('type').patchValue(v);
    } else {
      this.types = this._types;
    }
    if (this.types.length === 1) {
      this.selectedType = this.types[0].label;
    }
    // this.message = replaceAllVariableDefaultValue(this.message);
    //`{% comment %}START_COND{% endcomment %}${finalCondition}{% comment %}END_COND{% endcomment %}`;
  }

  selected(message) {
    navigator.clipboard.writeText(message.replaceAll('<br>', '\n')).then((c) => {
      this.generalService.notification('Message copied to clipboard', 'COPY');
    });
  }

  generate() {
    let error = false;
    const value = this.form.value;
    let observer = of(null);
    switch (this.form.value.type) {
      case AI_ACTION.BULLET_POINT: {
        observer = this.textAiService.generateMessageByBulletPoints(this.message, value.tone, value.settings || {});
        break;
      }
      case AI_ACTION.INSTRUCTION: {
        const instructions = value.instructions;
        if(!instructions.about) {
          this.generalService.notification('Description is required.', '', 3000, 'addin-snackbar');
          error = true;
        }
        value.instructions.tone = value.tone;
        observer = this.textAiService.generateMessageByInstructions(value.instructions);
        break;
      }
    }
    this.loading = true;
    this.loadingState.emit(this.loading);
    if(error) {
      return;
    }
    observer
      .pipe(take(1))
      .subscribe((res) => {
        if (res.messages) {

          this.messages = (res && res.messages || []).map(m => plainVariableToProsVariable(m));
          if (this.messages.length === 1) {
            this.state = {message: this.messages[0], type: value.type};
          } else {
            this.state = {messages: this.messages, type: value.type};
          }
        } else if (res.message) {
          this.messages = [res.message];
          this.state = {message: res.message, type: value.type, value: this.form};
        }
        this.state.value = this.form.value;
        if (this.messages.length) {
          for (let i = 0; i < this.messages.length; i++) {
            this.messages[i] = this.messages[i].replaceAll('\n', '<br>');
          }
          this.state.messages = this.messages;
        }
        if (this.cacheId) {
          this.textAiService.setResults(this.cacheId, this.state);
        }
        this.loading = false;
        this.loadingState.emit(this.loading);
      });
  }

  add() {
    this.passMessage.emit(this.state);
    console.log('helllllo');
  }

  ngAfterViewInit(): void {
    try {
      this.overlayRef.updatePosition();
    } catch (e) {

    }
  }

  logout() {
    this.authService.SignOut().then(() => {
      location.reload();
    });
  }

  ngOnDestroy(): void {
  }

}
