import {Component, Input, OnDestroy} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";

@Component({
  template: '',
})
export abstract class DestroyObserver implements OnDestroy {
  notifier = new Subject();
  notifier$ = takeUntil(this.notifier);

  unsubscribe() {
    this.notifier.next();
    this.notifier.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
