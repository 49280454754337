import {Component, ElementRef, Injector, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {TextAiService} from "@services/text-ai.service";
import {ActivatedRoute, Router} from "@angular/router";
import {combineLatest, forkJoin, fromEvent, merge, of, race} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, tap} from "rxjs/operators";
import {OPERATION_METHOD} from "@const/operation-method.enums";
import  editIcon from '@iconify/icons-mdi-light/pencil';
import {MatDialog} from "@angular/material/dialog";
import {TemplateComponent} from "@pages/templates/template.component";
@Component({
  selector: 'app-template-select',
  template: `
    <input  style="background: rgba(244,244,244,0.93);outline: none !important;" placeholder="search template" #searchText class="p-10 fw toolbar-input toolbar-input-border"/>
    <div style="height: 250px;overflow: auto;">
      <ng-container *ngIf="!isLoading; else loader;">
        <div (click)="selectTmp(template)" class="tmp-item" *ngFor="let template of templates" fxLayout="row">
          <div class="fw p-l-10" fxLayout="column">
            <div style="display: flex;align-items: center;font-weight: 500;font-size: 1.1em;"><span>{{template.name}}</span><span class="tmp-shortcut">{{template.shortcut}}</span></div>
            <div class="desc">{{template.sum}}</div>
          </div>
          <ic-icon (click)="editTemplate($event, template)" class="m-r-5 edit-tmp"
                   style="display: flex;align-items: center;font-size: 20px" [icIcon]="editIcon"></ic-icon>
        </div>
      </ng-container>
      <ng-template  #loader>
        <img class="tmp-loader" src="https://writi.io/wp-content/uploads/2022/06/dots-loader.svg">
      </ng-template>
    </div>
    <div style="background: rgba(244,244,244,0.93);cursor: pointer;" class="p-10">
      <a (click)="newTmp()" class="new-tmp pull-r">+ New template</a>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
      height: 100%;
      border-radius: 4px !important;
      border: 1px solid #dcdfe6 !important;
      display: flex;
      flex-direction: column;
      min-width: 300px;
    }
    .tmp-loader {
      max-width: 60px;
      width: 100%;
      margin: auto auto;
      height: 100%;
      display: block;
    }
    .tmp-shortcut {
      margin: 2px 7px;
      background: #1e73eb;
      padding: 3px;
      font-size: 11px;
      color: white;
      border-radius: 8px;
    }
    .new-tmp {
      background-color: #1d73eb;
      color: white;
      padding: 5px 10px;
      border-radius: 4px;
    }
    .tmp-item {
      cursor: pointer;
      padding: 5px 2px;
    }

    .desc {
      overflow: hidden;
      text-overflow: ellipsis;
      color: #747474;
      font-size: 13px;
    }

    .edit-tmp {
      display: none !important;
      cursor: pointer;
    }

    .tmp-item:hover {
      background: rgba(128, 128, 128, 0.12);
    }

    .tmp-item:hover .edit-tmp {
      display: block !important;
    }

  `]
})
export class TemplateSelectComponent implements OnInit {
  editIcon = editIcon;
  isLoading = false;
  @ViewChild('searchText', {static: true}) input: ElementRef;
  @Output() onEvent = new EventEmitter();
  templates = [];
  constructor(
    private authService: AuthService,
    private textAiService: TextAiService,
    private dialog: MatDialog) {

   }

  ngOnInit(): void {

  }

  newTmp() {
    const ref = this.dialog.open(TemplateComponent, {
      panelClass: ['writi-root', 'no-padding'],
      backdropClass: 'no-padding',
      data: {
        templateId: 'new',
        // sideBarOn: false,
      }
    });
    ref.afterClosed()
      .pipe(
        take(1),
        switchMap(()=> this.getTemplates(''))
      ).subscribe((res)=> {
    })
  }

  selectTmp(template) {
    console.log('selectTmp', template);
    this.onEvent.emit(template);
  }

  editTemplate(event, template) {
    event.preventDefault();
    event.stopPropagation();
    const ref = this.dialog.open(TemplateComponent, {
      panelClass: ['writi-root', 'no-padding'],
      backdropClass: 'no-padding',
      data: {
        templateId: template.id,
      }
    });
    ref.afterClosed()
      .pipe(
        take(1),
        switchMap(()=> this.getTemplates(''))
      ).subscribe((res)=> {
    })
  }

  getTemplates(value) {
    this.isLoading = true;
    return this.textAiService.templatesCrud({
      method: OPERATION_METHOD.INDEX,
      freeText: value
    }).pipe(
      tap((resp)=> {

        this.templates = [];
        resp.entities.forEach((tmp)=> {
          const tempDivElement = document.createElement("div");

          // Set the HTML content with the given value
          tempDivElement.innerHTML = tmp.content;

          // Retrieve the text property of the element
          const text = tempDivElement.textContent || tempDivElement.innerText || "";
          tmp.sum = text.slice(0, 33).replaceAll('\n', ' ');
          tmp.text = text;
          this.templates.push(tmp);
        })
        this.isLoading = false;
      })
    );
  }


  ngAfterViewInit() {
    // server-side search
      this.isLoading = true;
      fromEvent(this.input.nativeElement,'keyup').pipe(
        map((event:KeyboardEvent)=> this.input.nativeElement.value),
        startWith(''),
      )
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((value) => {
         return this.getTemplates(value)
        }),
        tap(()=> this.isLoading = false))
      .subscribe();
  }

  onComplete(e) {

  }

  onEvents($event){

  }

}
