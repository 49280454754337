import {
  AfterViewInit,
  Component,
  ElementRef, forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {GeneralService} from "@services/general.service";
import {LangOptions} from "@const/lang.enums";
import {CacheService} from "@services/cache.service";

@Component({
  selector: 'lang-select',
  template: `
    <div  class="settings-wrapper flex-col" [style.max-width.px]="maxWidth">
        <div class="toolbar-input toolbar-input-border fc fr">
          <mat-select (openedChange)="onOpen()" (focus)="onOpen()" panelClass="writi-root" [formControl]="langCtl" placeholder="Language">
            <mat-option *ngFor="let lang of langs" [value]="lang.value">
              {{lang.label}}
            </mat-option>
          </mat-select>
        </div>
    </div>
  `,
  styles: [`
   :host {
     min-width: 120px;
     background: white;
   }
   :host .toolbar-input {
     width: 100%;
     min-width: 120px;
     height: 35px;
   }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LangSelectComponent),
      multi: true,
    }
  ],
})
export class LangSelectComponent implements OnDestroy, OnInit, AfterViewInit, ControlValueAccessor {
  panelOpenState = false;
  langs = LangOptions;
  langCtl = new UntypedFormControl('')
  @Input() maxWidth = 300;
  private onChange = (_: any) => null;
  private onTouched = () => null;

  constructor(private generalService: GeneralService, private cacheService: CacheService) {
  }

  onOpen() {
    this.generalService.addClassToOverlay();
  }

  ngOnInit(): void {
    this.langCtl.valueChanges.subscribe((lang)=> {
      if(lang) {
        this.cacheService.save({key: 'lang', data: lang});
      }
      this.onChange(lang);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {
      this.panelOpenState = true;
    }, 500);
    const lang = this.cacheService.load('lang');
    if(lang) {
      this.langCtl.patchValue(lang);
    }
  }

  ngOnDestroy(): void {
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(tone: any): void {
    this.langCtl.patchValue(tone, {emitEvent: false});
  }

}
