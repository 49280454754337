import {Injectable, NgZone} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import firebase from 'firebase/compat/app';
import {BehaviorSubject, from, Observable, of, Subscription} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AppUser} from "@models/app-user";
import {getValueByPath} from "@helper-functions/get-value-by-path";
import {catchError, filter, switchMap, take, tap} from "rxjs/operators";
import OAuthProvider = firebase.auth.OAuthProvider;
import {environment} from "@environment";
import {getUrlParams, isUrlContain} from "@helper-functions/get-url-params";
import {delay} from "@helper-functions/delay";
import {GeneralService} from "@services/general.service";
import {addUrlParam} from "@helper-functions/add-url-param";
declare var chrome;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public user$: Observable<any> = this.user.asObservable();
  public userCollections: any;
  public sub: Subscription;
  private isExtension = false;

  constructor(public afs: AngularFirestore,   // Inject Firestore service
              public afAuth: AngularFireAuth, // Inject Firebase auth service
              public generalService: GeneralService,
              public ngZone: NgZone) { // NgZone service to remove outside scope warning)
    this.userCollections = this.afs.collection('users');
    this.afAuth.user.subscribe(async (user) => {
      if (user) {
        try {
          this.sub.unsubscribe();
        } catch (e) {

        }

        let sub = null, customer = null;
        this.sub = this.afs.collection('users').doc(user.uid)
          .valueChanges().subscribe(async (response) => {
            const appUser: any = new AppUser(user.toJSON());
            const respp = await this.subscription(appUser.id());
            sub = respp.sub;
            customer = respp.customer;
            appUser.setSubscription(sub, customer);
            this.user.next(appUser);
            if (appUser && appUser.uid && isUrlContain('/signin') && isUrlContain('app.writi.io/')) {
              window.location.reload();
            } else if (appUser && appUser.uid && isUrlContain('/login') && isUrlContain('app.writi.io/')) {
              // window.close();
            }
          });
      } else {

        this.user.next(new AppUser(null));
      }
    });
  }

  getFreeUserQuota() {
    return this.generalService.createFnGetRequest('free-user-quota').toPromise();
  }

  activeExtensionListener() {
    this.isExtension = true;
    // chrome.runtime.onMessage.addListener((response, sendResponse) => {
    //
    // });
  }

  User() {
    return this.user.getValue();
  }

  async updateSingleAction(action) {
    return this.generalService.createFnPostRequest('update-single-action', {
      action
    }).toPromise();
  }

  async updateCurrentUserProm(user) {
    let appUser = null;
    try {
      const cred = getValueByPath(user, 'credential');
      const token = getValueByPath(user, 'token');
      if (cred) {
        const credential = new OAuthProvider(cred.providerId).credential(cred);
        const storage = await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        appUser = await this.afAuth.signInWithCredential(credential);
        appUser = this.user.getValue();
      } else if (token) {
        appUser = await this.afAuth.signInWithCustomToken(token);
        appUser = this.user.getValue();
        // .pipe(
        //   tap((res)=> {
        //     console.log(res);
        //     debugger;
        //   }),
        //   switchMap(()=> this.user$),
        //   filter((user: AppUser)=> user.isLoggedIn),
        //   catchError((e)=> {
        //     console.log('ERRRR', e);
        //     debugger;
        //     return of({error: true});
        //   })
        // )
      }
    } catch (e) {
      console.log('updateCurrentUser:CATCH', e);
    }
    console.log('updateCurrentUserProm', appUser)
    return appUser;
  }


  updateCurrentUser(user) {
    let observer = of({});
    try {
      const cred = getValueByPath(user, 'credential');
      const token = getValueByPath(user, 'token');
      if (cred) {
        const credential = new OAuthProvider(cred.providerId).credential(cred);
        observer = from(this.afAuth.signInWithCredential(credential))
          .pipe(
            tap(() => console.log('updateCurrentUser:USER 2')),
            switchMap(() => this.user$),
            filter((user: AppUser) => user.isLoggedIn),
            catchError((e) => {
              console.log('updateCurrentUser:ERRR tap ', e);
              console.log('updateCurrentUser:ERRRR', e);
              return of({error: true});
            })
          )
      } else if (token) {
        console.log('token wise');
        observer = from(this.afAuth.signInWithCustomToken(token))
          .pipe(
            tap(() => console.log('updateCurrentUser:USER 1')),
            switchMap(() => this.user$),
            filter((user: AppUser) => user.isLoggedIn),
            catchError((e) => {
              console.log('ERRRR', e);
              return of({error: true});
            })
          )
      }
    } catch (e) {
      console.log('updateCurrentUser:CATCH', e);
    }
    observer.subscribe((res) => {
      console.log('updateCurrentUser:',res);
    }, (e) => {
      console.log(e);
    })
    return observer;
  }

  signInWithCustomToken(token) {
    return firebase.auth().signInWithCustomToken(token);
  }

  async subscription(uid, retries = 0) {
    const customerRef = await this.afs.collection('customers').ref.doc(uid).get();
    const userRef = await this.afs.collection('users').ref.doc(uid).get();
    const user: any = userRef.data();
    let customer: any = customerRef.data() || {};
    if ((!user) && retries < 30) {
      retries++;
      console.log('RETRY FETCH SUBSCRIPTION', retries);
      await delay(retries * 300);
      return this.subscription(uid, retries);
    }
    customer = {...customer, ...user};
    if (customer.free) {
      customer.trial = false;
      const quota: any = await this.getFreeUserQuota();
      customer.quotaCharsLeft = quota.actionsRemaining;
      customer.quotaCharsUsed = 10 - quota.actionsRemaining;
      console.log('i am free 111', customer);
    }
    const subs = await customerRef.ref.collection('subscriptions').get();
    const sub: any = subs && subs.docs && subs.docs[0] ? subs.docs[0].data() : null;
    if (sub && sub.product) {
      let product = await sub.product.get();
      product = product && product.data();
      sub.name = product && product.name;
    }
    return {sub, customer};
  }


  isLoggedIn(): boolean {
    const user = this.user.getValue();
    return (user !== null && user.isLoggedIn);
  }

  // Sign in with Google
  GoogleAuth(loginType = null) {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider(), false, loginType);
  }

  FacebookAuth(loginType = null) {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider(),false, loginType);
  }

  YahooAuth(loginType = null) {
    const provider = new OAuthProvider('yahoo.com');
    provider.setCustomParameters({
      prompt: 'login',
    });

    return this.AuthLogin(provider, false, loginType)
  }

  MicrosoftAuth(loginType, closeWindow = true) {
    const provider = new OAuthProvider('microsoft.com');
    return this.AuthLogin(provider, closeWindow, loginType)
  }

  // Auth logic to run auth providers
  AuthLogin(provider, closeWindow = false, loginType = null) {
    if (environment.platform && environment.platform === 'addin') {
      this.afAuth.getRedirectResult().then(getRedirectResult => {
        return getRedirectResult;
        // let token = result.credential.
        // console.log(this.user);
      }).catch((error) => {
        console.log('err getRedirectResult login', error);
      });
      return this.afAuth.signInWithRedirect(provider)
        .then((signInWithRedirect) => {
          if (closeWindow) {
            // window.close();
          }
          // this.ngZone.run(() => {
          // });
        }).catch((error) => {
          console.log('err signInWithRedirect login', error);
        })
    } else {
      return this.afAuth.signInWithPopup(provider)
        .then((result) => {
          const cred = result && result.credential && result.credential.toJSON ? {credential: result.credential.toJSON()} : null;
          return this.handleLoginResponse(cred, loginType);
        }).catch((error) => {
          console.log('err signInWithPopup login', error);
          return false;
        })
    }
  }

  async getUserObj(uid: string) {
    let retries = 0;
    let user = null;
    while (retries < 10 && !user) {
      const userRef = await this.afs.collection('users').ref.doc(uid).get();
      if (userRef && userRef.data()) {
        user = userRef.data();
      } else {
        retries++;
        console.log('retries', retries);
        await delay(400);
      }
    }
    return user;
  }

  async asyncUrlLogin(result) {
    const params: any = getUrlParams();
    const exRedirect = params.exRedirect;
    try {
      const credential: any = result.credential || result;

      let user = null;
      if (exRedirect) {
        if(result.credential) {
          const cred = new OAuthProvider(credential.providerId).credential(credential);
          const appUser = await this.afAuth.signInWithCredential(cred);
          user = await this.getUserObj(appUser.user.uid)
        } else {
          const appUser = await this.afAuth.signInWithCustomToken(result.token);
          user = await this.getUserObj(appUser.user.uid)
        }
        // if (user && user.options && user.options.features && user.options.features.redirectLogin) {
          if (user) {
          console.log('async redirect login');
          const url = addUrlParam(exRedirect, 'writi-cred', JSON.stringify(credential));
          if(result.isBlocked) {
            window.location.assign( url );
          } else {
            console.log('open url', url);
            window.open(url, '_blank');
          }
        }
      }
    } catch (e) {
      console.log('LOGIN FAILED');
      console.log('LOGIN FAILED', e);
    }
    return result;
  }

  async handleLoginResponse(result, loginType = null) {
    try {
      debugger;
      console.log('logintype', loginType);
      const credential: any = result.credential;
      window.document.body.focus();
      const isBlocked = this.checkIfBlocked();
      try {
        result.isBlocked = isBlocked;
      } catch (e) {

      }
      result.app = 'writi';
      if(loginType == 'ex') {
        await this.asyncUrlLogin(result);
        window.postMessage(result, '*');
        console.log('LOGIN CRED SENT');
      }
    } catch (e) {
      console.log('LOGIN FAILED');
      console.log('LOGIN FAILED', e);
    }
    return result;
    // const params = getUrlParams();
    // try {
    //   if(result && isUrlContain('/login')) {
    //     console.log('LOGIN WORKS');
    //     const credential = result.credential;
    //     console.log('VERSION 55555=>');
    //     window.document.body.focus();
    //     window.postMessage({credential, app: 'writi'}, "*");
    //     setTimeout(()=> {
    //       console.log('close 2');
    //       // window.close();
    //     }, 3000);
    //   } else {
    //     console.log('LOGIN ERROR')
    //   }
    // } catch (e) {
    //   console.log('ex err', e);
    // }
  }

  async handleExTokenResponse(result, loginType = null) {
    debugger;
    console.log('AUTH FLOW');
    if (result) {
      window.document.body.focus();
      result.app = 'writi';
      console.log('logintype', loginType);
      if(loginType == 'ex') {
        const isBlocked = this.checkIfBlocked();
        try {
          result.isBlocked = isBlocked;
        } catch (e) {

        }
        await this.asyncUrlLogin(result);
        window.postMessage(result, "*");
        setTimeout(() => {
          console.log('close 3');
          // window.close();
        }, 3000);
      }
    }
  }

  goToDashboard() {
    window.location.href = 'https://app.writi.io/tools'
  }

  checkIfBlocked() {
    let PUtest = window.open(null, "", "width=100,height=100");
    try {
      PUtest.close();
      return false;
    } catch (e) {
      return true;
    }
  }

  // Sign out
  async SignOut() {
    this.generalService.createFnPostRequest('revokeRefreshTokens', {}).pipe(take(1)).subscribe();
    window.postMessage({writiSignOut: true}, "*");
    return this.afAuth.signOut().then(() => {
      setTimeout(()=> {
        window.location.reload();
      }, 500);
    })
  }
}
