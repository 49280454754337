console.log('ENV ELEMENTS PRODUCTION');
export const environment: any = {
  ASSETS_URL: 'https://elements.writi.io',
  reCaptchaKey: '6LdWrQYeAAAAAC4uc1NYSGYrZf11L0BTSqxAIYOO',
  firebase: {
    apiKey: "AIzaSyBF-BN3RM4o3aB5rrdH3_awfGFqB_gPDic",
    authDomain: "auth.writi.io",
    projectId: "writi-337101",
    storageBucket: "writi-337101.appspot.com",
    messagingSenderId: "829337842286",
    appId: "1:829337842286:web:7aa1b3882d450d7e876c7f",
    measurementId: "G-91SZESP8TJ"
  },
  production: true
};
