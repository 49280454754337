import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TextAiService} from "@services/text-ai.service";
import {ReCaptchaV3Service} from "ngx-captcha";
import {GeneralService} from "@services/general.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'multi-widget',
  template:`
    <div class="widget-wrapper">
      <div class="tab-btns" [ngClass.gt-sm]="'md-space'" fxLayout="row">
        <a (click)="activeItem = 1">Instructions</a>
        <a (click)="activeItem = 2">Follow up</a>
        <a (click)="activeItem = 3">Subject Line</a>
      </div>
      <div class="widgets">
        <div class="fw" *ngIf="activeItem === 1" @inOutPaneAnimation #paneContent>
          <div class="widget-title">{{titles.instructions}}</div>
          <gpt3-suggest-message [noHeader]="true" [showAdd]="false" class="fw"></gpt3-suggest-message>
        </div>
        <div class="fw" *ngIf="activeItem === 2" @inOutPaneAnimation #paneContent>
          <div class="widget-title">{{titles.followUp}}</div>
          <follow-up-widget class="fw"></follow-up-widget>
        </div>
        <div class="fw" *ngIf="activeItem === 3" @inOutPaneAnimation #paneContent>
          <div class="widget-title">{{titles.subjectLine}}</div>
          <subject-line-widget class="fw"></subject-line-widget>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .widgets {
      width: 100%;
      /*background: white;*/
    }
    .widget-title {
      padding: 20px;
      font-size: 22px;
      text-align: center;
    }
    .widget-wrapper {
      position: relative;
    }

    .tab-btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .md-space.tab-btns a {
      margin: 10px;
      padding: 8px 14px 8px 14px;
      font-size: 23px;
    }
    .tab-btns a {
      cursor: pointer;
      margin: 4px;
      padding: 4px 10px 4px 10px;
      border-radius: 25px 25px 25px 25px;
      color: #3f93f5;
      background: rgb(63 147 245 / 20%);
    }
  `],
  animations: [
    trigger("inOutPaneAnimation", [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        // style({ position: 'absolute' }),
        animate('300ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ])
  ]
})
export class MultiWidget implements OnInit {
  activeItem = 1;
  @Input() titles = {
    instructions: 'Generate email by instruction.',
    followUp: 'Generate email follow up',
    subjectLine: 'Generate email subject line'
  }
  constructor(private textAiService: TextAiService,
              private generalService: GeneralService,
              private reCaptchaV3Service: ReCaptchaV3Service) {

  }

  ngOnInit() {
  }

}
