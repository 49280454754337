import {Component, ElementRef, EventEmitter, Inject, OnInit, Optional, Output} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {AppUser} from "@models/app-user";
import {StripeService} from "@services/stripe.service";
import {Popover} from "@widgets/popover/popover.service";
import {ASSETS_URL} from "@const/global.enums";
import {GeneralService} from "@services/general.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import logoutIcon from '@iconify/icons-mdi-light/logout';
import {TextAiService} from "@services/text-ai.service";
import {take} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-user-profile',
  template: `
    <div *ngIf="user" fxLayout="column" [class.ex-view-profile]="ex" class="profile-wrapper app-container">
      <div fxLayout="column" class="profile-header bg-primary">
        <div style="position: relative;" fxLayout="column" fxLayoutAlign="center center" [style.padding-top.px]="paddingTop" class="top-profile fw">
                    <div *ngIf="ex" class="flex-center logout-profile" style="background: #3f93f5;">
                      <button (click)="logout()" mat-icon-button class="pull-r">
                        <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" color="white" [icon]="logoutIcon"></ic-icon>
                      </button>
                    </div>
          <h1 class="brand">writi.io</h1>
          <div class="img-profile medium round">
            <img class="fw" [src]="user.get('photoURL')"/>
          </div>
        </div>
      </div>
      <div fxLayout="column" class="profile-content">
        <div fxLayout="column" fxLayoutAlign="center center" class="profile-info">
          <h2 class="first name">
            Ahoy {{user.firstName}}
          </h2>
          <div *ngIf="user.isInTrial " class="m-b-10 b-light">
            Free trial expires {{user.trialExpiredDate | date}}
          </div>
          <div *ngIf="user.free" class="m-b-10 b-light">
            Free account
          </div>
          <div class="m-b-10 b-light">
            Last login {{user.get('lastLoginAt') | date}}
          </div>
          <div *ngIf="user && user.priceId" class="m-b-10 b-light">
            <a class="manage-sub-btn" href="javascript:void(0)" (click)="navigate({url: 'payment'})">Manage subscription</a>
          </div>
        </div>
        <div fxLayout="column" class="profile-credit p-15">
          <div *ngIf="!user.isInTrial" class="m-b-10 fw" fxLayout="row">
            <div class="ink-icon-wrap" fxFlexAlign="start">
                <img class="fw" src="https://writi.io/wp-content/uploads/writi-assets/profile/ink.png"/>
            </div>
            <div class="notice b-light fw" fxFlexAlign="end">
              You've used about {{user.usedPercentage}}% of your ink
            </div>
          </div>
          <mat-progress-bar *ngIf="!user.isInTrial" class="m-b-20" mode="determinate" [value]="user.usedPercentage"></mat-progress-bar>

          <app-payment [hideFooter]="true" [hideBtn]="true" [user]="user" *ngIf="showPrices"></app-payment>
<!--          <button (click)="showPrices = !showPrices" class="buy-more app-btn bg-primary" fxFlexAlign="end">-->
<!--            Buy more ink-->
<!--          </button>-->

          <button  *ngIf="!user?.affiliate?.id" class="p-5 m-15" (click)="becomeAffiliate()" mat-flat-button color="primary">Become affiliate</button>
          <button *ngIf="user?.affiliate?.id" class="p-5 m-15" (click)="goToAffiliateDashboard()" mat-flat-button style="background: green;color: white">Affiliate dashboard</button>
        </div>

<!--        <div *ngIf="!ex" fxLayout="row wrap" fxLayoutAlign="center stretch">-->
<!--          <div  *ngFor="let profileBox of profileBoxes" fxFlex="50" fxFlex.xs="100" fxLayout="column">-->
<!--            <div fxLayout="column" class="profile-box">-->
<!--              <div fxLayout="row" class="p-20 fh">-->
<!--                <div fxLayout="column" fxLayoutAlign="center end" class="desc fw">-->
<!--                  <div class="fw app-title">-->
<!--                    {{profileBox.title}}-->
<!--                  </div>-->
<!--                  <div class="fw app-secondary-title">-->
<!--                    {{profileBox.desc}}-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div fxLayout="row" fxLayoutAlign="center center" class="box-icon">-->
<!--                  <img [src]="profileBox.img" />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="menu-link">-->
<!--                <button (click)="navigate(profileBox)" fxFlex="100" mat-flat-button>{{profileBox.linkTitle}}</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>`,
  styles: [`
    .first.name {
      color: black;
    }
    .manage-sub-btn {
      color: #438de6;
      font-weight: 500;
      cursor: pointer;
    }
    .logout-profile {
      position: absolute;
      top: 5px;
      right: 5px;
      background: transparent !important;
    }
    .ink-icon-wrap {
      max-width: 20px;
      width: 100%;
      margin-bottom: -5px;
    }
    .ex-view-profile {
      margin: 0 !important;
      padding: 0 !important;
    }
    .profile-box {
      border: 1px solid #dadce0;
      border-radius: 8px;
      box-sizing: border-box;
      margin: 12px;
      overflow: hidden;
      height: 100%;
    }
    .box-icon {
      /*width: 100px;*/
      padding: 10px;
    }
    .box-icon img {
      width: 80px;
      /*width: 100%;*/
    }
    .menu-link {
      border-top: 1px solid #dadce0;
    }
    .menu-link button {
      border-radius: 0;
      color: #1d73eb;
      padding: 17px 24px 18px 24px;
      letter-spacing: .01785714em;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.25rem;
      hyphens: auto;
      word-break: break-word;
      word-wrap: break-word;
    }
    .profile-content{
      padding: 12px;
    }
    .top-profile {
      /*padding-top: 130px;*/
      /*background-image: url('https://app.writi.io/assets/bg-map.png');*/
      background-image: url('/assets/bg/bg-map.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    .buy-more {
      font-size: 14px;
      display: inline-block;
      padding: 6px 15px;
      max-width: 122px;
      border-radius: 15px 0 0 15px;
    }

    .profile-credit {
      width: 100%;
      max-width: 550px;
      margin: auto auto;
    }

    .profile-credit .notice {
      font-size: 12px;
      text-align: right;
    }

    .profile-info {
      padding-top: 40px;
    }

    .top-profile .img-profile {
      border: solid 7px #ffffff85;
      margin-bottom: -30px;
    }

    .brand {
      font-weight: bold !important;
      font-size: 30px !important;
    }

    .profile-btn {
      width: 100%;
      max-width: 180px;
      height: 65px;
      margin-bottom: 10px !important;
    }

    .profile-btn img {
      width: 100%;
    }

    .subscribe-btn {
      width: 240px;
    }
  `]
})
export class UserProfileComponent implements OnInit {
  logoutIcon = logoutIcon;
  @Output() events = new EventEmitter();
  ex: boolean = false;
  paddingTop = 130;
  user: AppUser;
  showPrices = true;
  chromeExUrl = `${ASSETS_URL}/assets/chrome-extension.png`;
  bgUrl = `${ASSETS_URL}/assets/bg/bg-map.png`;
  inkIcon = `${ASSETS_URL}/assets/ink.png`;
  loading = false;
  items = [1,3,4,4,4,]
  profileBoxes = [
    {
      title: 'Tools',
      desc: 'Generate original emails within seconds. Write relevant replies within the same message thread, based on previous messages. Generate  follow-up messages to unanswered emails that can be copied into your favorite CRM tool.',
      img: 'https://writi.io/wp-content/uploads/2022/03/Tools_dashboard.png',
      linkTitle: 'Go to tools',
      url: 'tools'
    },
    // {
    //   title: 'Help Videos',
    //   desc: 'Learn all of the ways you can use writi with our short video tutorials.',
    //   img: 'https://writi.io/wp-content/uploads/2022/03/Help_video_tot.png',
    //   linkTitle: 'Go to help videos',
    //   url: 'videos'
    // },
    // {
    //   title: 'Platforms',
    //   desc: `You can use writi on desktop by installing the Chrome Extension.
    //           You can use writi on mobile by installing the Workspace Add-on.`,
    //   img: 'https://writi.io/wp-content/uploads/2022/03/Integration_.png',
    //   linkTitle: 'Go to platforms',
    //   url: 'extensions'
    // },
    {
      title: 'Subscriptions',
      desc: `Manage subscriptions`,
      img: 'https://writi.io/wp-content/uploads/2022/03/Memberships.png',
      linkTitle: 'Go to payments',
      url: 'payment'
    },
    // {
    //   title: 'You have security recommendations',
    //   desc: 'Recommended actions found in the Security Checkup',
    //   img: 'https://www.gstatic.com/identity/boq/accountsettingsmobile/securitycheckup_yellow_96x96_8a13298be6a17ecf51cdf9baad9c74f1.png',
    //   linkTitle: 'Review suggestions (4)',
    //   link: ''
    // }
  ]
  constructor(private popper: Popover,
              private authService: AuthService,
              private textAiService: TextAiService,
              public stripeService: StripeService,
              private generalService: GeneralService,
              private snackBar: MatSnackBar,
              private el: ElementRef,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data) {
       this.paddingTop = 20;
       if(this.data && this.data.ex) {
         this.ex = true;
       }
    }
  }

  ngOnInit(): void {
    this.authService.user$.subscribe(async (user: AppUser) => {
      this.user = user;
      if(user && user.free) {
        const quota: any = await this.authService.getFreeUserQuota();
        if(quota && quota.actionsRemaining) {
          user.updateQuota(quota.actionsRemaining, 10);
        }
      }
    });
    // this.stripeService.defaultProduct$.subscribe((p)=> {
    //
    // })
  }

  goToAffiliateDashboard() {
    this.textAiService.affiliateSSO()
      .pipe(take(1))
      .subscribe((res)=> {
        this.snackBar.open('Redirect to affiliate dashboard.');
        if(res && res.url) {
          window.open(res.url, "_blank");
        } else {
          window.open(
            "https://writi.getrewardful.com/", "_blank");
        }
      }, ()=> {
        window.open(
          "https://writi.getrewardful.com/", "_blank");
      });
  }

  ngAfterViewInit() {
    if(this.data) {
      const el = this.el.nativeElement;
      const contentRef = el.querySelector('.profile-content');
      if(contentRef) {
        contentRef.style.maxHeight = (window.innerHeight - 164) + 'px';
        contentRef.style.overflow = 'auto';
      }
    }
  }

  billingPortal() {
    this.loading = true;
    this.stripeService.billingPortal().then(() => {
      // this.loading = false;
    });
  }

  navigate(profileBox) {
    console.log('NAVIGATE....', profileBox);
    if(profileBox.url === 'payment') {
      this.billingPortal();
    } else {
      this.events.emit({name: 'navigate', value: profileBox});
    }
  }

  logout() {
    this.authService.SignOut().then(() => {
      // location.reload();
    });
  }

  becomeAffiliate() {
    this.textAiService.createAffiliate()
      .pipe(take(1))
      .subscribe((res)=> {
        if(res && res.link) {
          this.snackBar.open('Affiliate account created.');
        } else {
          this.snackBar.open('create affiliate account failed.');
        }
      });
  }


  subscribe(product) {
    this.loading = true;
    this.stripeService.checkout(product).then((res) => {
      this.loading = false;
    });
  }

}
