export const LangOptions = [
  {label: 'Hebrew', value: 'he'},
  {label: 'Dutch', value: 'nl'},
  {label: 'French', value: 'fr'},
  {label: 'German', value: 'de'},
  {label: 'Russian', value: 'ru'},
  {label: 'Spanish', value: 'es'},
  {label: 'Italian', value: 'it'},
]


export const LangDirection = {
  'he': 'rtl'
}
