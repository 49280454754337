import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from "@visurel/iconify-angular";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {BulletpointsAreaComponent} from "@widgets/bulletpoints-area/bulletpoints-area.component";
import {BulletpointsAreaDirective} from "@widgets/bulletpoints-area/bulletpoints-area.directive";


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        IconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
  declarations: [
    BulletpointsAreaComponent,
    BulletpointsAreaDirective
  ],
  exports: [
      BulletpointsAreaComponent,
    BulletpointsAreaDirective
  ]
})
export class BulletpointsAreaModule {
}
