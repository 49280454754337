import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from "@visurel/iconify-angular";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {MatListModule} from "@angular/material/list";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {Gpt3SettingsModule} from "../gpt3-settings/gpt3-settings.module";
import {Gpt3ToneModule} from "../gpt3-tone/gpt3-tone.module";
import {TrustHtmlModule} from "@app/shared/trust-html/trust-html.module";
import {ComposeAiComponent} from "./compose-ai.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {LoadingButtonModule} from "@widgets/loading-button/loading-button.module";
import {BulletpointsAreaModule} from "@widgets/bulletpoints-area/bulletpoints-area.module";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FlexLayoutModule} from "@angular/flex-layout";
import {LangSelectModule} from "@widgets/gpt3-ui/lang-select/lang-select.module";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {TemplateSelectModule} from "@pages/templates/template.select.module";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        IconModule,
        MatButtonModule,
        Gpt3SettingsModule,
        ReactiveFormsModule,
        MatListModule,
        MatRadioModule,
        MatSelectModule,
        Gpt3ToneModule,
        MatInputModule,
        TrustHtmlModule,
        MatToolbarModule,
        LoadingButtonModule,
        BulletpointsAreaModule,
        MatSlideToggleModule,
        FlexLayoutModule,
        LangSelectModule,
        MatDialogModule,
        MatCheckboxModule,
    ],
  declarations: [
    ComposeAiComponent,
  ],
  exports: [
    ComposeAiComponent,
  ]
})
export class ComposeAiModule {
}
