import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

declare var chrome;

import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit, Optional,
} from '@angular/core';
import {DestroyObserver} from "@app/abstracts/destroy-observer";
import {DomSanitizer, SafeHtml, SafeResourceUrl} from "@angular/platform-browser";
import closeIcon from '@iconify/icons-mdi-light/plus';
import {AuthService} from "@services/auth.service";
import {platform} from "@floating-ui/dom";


@Component({
  selector: 'video-learning-comp',
  template: `
    <div class="p-10">
      <div class="flex-center">
        <div *ngIf="title" style="padding-bottom:10px;text-align:center;font-size: 36px;color: black;">{{title}}</div>
        <div class="pull-r">
          <button (click)="closeModal()" style="font-size: 35px;transform: rotate(45deg)" mat-icon-button>
            <ic-icon [icon]="closeIcon"></ic-icon>
          </button>
        </div>
      </div>
      <div style="color:black !important;text-align: center;padding-bottom:10px;" *ngIf="topText"
           [innerHTML]="topText"></div>
<!--      <iframe-->
<!--        *ngIf="!widgetUrl"-->
<!--        width="560"-->
<!--        height="315"-->
<!--        [src]="playlist"-->
<!--        title="Linkedin videos"-->
<!--        frameborder="0"-->
<!--        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--        allowfullscreen></iframe>-->
<!--      <iframe *ngIf="widgetUrl" [src]="widgetUrl"-->
<!--              style="width:100%;height:300px;border:none;">-->
<!--      </iframe>-->
      <div class="gif-wrap flex-center" style="justify-content: center;">
        <img style="max-width: 500px;" class="fw" [src]="gifUrl"/>
      </div>
      <div style="color:black;text-align: center;padding-top:10px;" *ngIf="bottomText" [innerHTML]="bottomText"></div>
      <div style="display: flex;align-items: center;justify-content: center;">
          <span class="example-list-section">
            <mat-checkbox class="example-margin"
                          (change)="dontShow()">
              <strong>Do not show me again</strong>
            </mat-checkbox>
          </span>
      </div>
    </div>
  `,
  styles: [``],
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class VideoLearningComponent extends DestroyObserver implements OnInit, AfterViewInit, OnDestroy {
  gifUrl = '';
  playlists = {
    linkedin: 'https://www.youtube.com/embed/videoseries?list=PLojNw7u1h7IQgu97Jdhr5pUSMVtLdYZ8W',
    gmail: 'https://www.youtube.com/embed/4UzdvY1bXzE?rel=0&showinfo=1&ecver=2&enablejsapi=1&origin=https%3A%2F%2Fwriti.io&widgetid=1'
  }

  gifs = {
    linkedin: 'https://writi.io/wp-content/uploads/2023/03/comment_linkedin_writi-1.gif',
    gmail: 'https://writi.io/wp-content/uploads/2023/03/comment_linkedin_writi-2.gif'
  }

  widgetLinks = {
    linkedin: 'https://widget.taggbox.com/113163',

  }
  widgetUrl;
  playlist: SafeResourceUrl;
  @Input() topText: SafeHtml = '';
  @Input() bottomText: SafeHtml = '';
  @Input() title;
  closeIcon = closeIcon;

  constructor(private authService: AuthService, public dialogRef: MatDialogRef<VideoLearningComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public sanitizer: DomSanitizer) {
    super();
    if (data) {
      console.log('Learning.....', data);
      this.title = data.title;
      this.topText = data.topText ? this.sanitizer.bypassSecurityTrustHtml(data.topText) : null;
      this.bottomText = data.bottomText ? this.sanitizer.bypassSecurityTrustHtml(data.bottomText) : null;
      this.widgetUrl = data.widgetOn && this.widgetLinks[this.data.platform] ? this.widgetLinks[this.data.platform] : null;
      const playlist = data.url || this.playlists[data.platform];
      this.playlist = playlist ? this.sanitizer.bypassSecurityTrustResourceUrl(data.url || this.playlists[data.platform]) : null;
      if (this.widgetUrl) {
        this.widgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.widgetUrl);
      }
      this.gifUrl = this.gifs && this.gifs[this.data.platform] || '';
      console.log('Learning.....', data, this);
    }
  }


  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  dontShow() {
    this.authService.updateSingleAction('firstVideo');
    const flagKey = `videoShowed__linkedin`;
    localStorage.setItem(flagKey, 'true');
  }

  loadComponent() {

  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

}
