import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {environment} from "@environment";
import {AppInjector} from "./app/app-injector";
import {AppWebElementsModule} from "@app/app.web.elements.module";
console.log('VERSION 54321=>')
console.log('WEB ELEMENTS...');
if (environment.production) {
  enableProdMode();
  // if(window){
  //   window.console.log=function(){};
  // }
}

platformBrowserDynamic().bootstrapModule(AppWebElementsModule)
  .then((moduleRef) => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.error(err));
