import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
} from '@angular/core';
import {FormControlName, FormControl, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import emailIcon from '@iconify/icons-mdi-light/email';
import translateIcon from '@iconify/icons-ph/translate-light';
import copyIcon from '@iconify/icons-ph/copy-simple-thin';
import plusIcon from '@iconify/icons-ph/plus';
import {take, takeUntil} from "rxjs/operators";
import {of} from "rxjs";
import {TextAiService} from "@services/text-ai.service";
import {GeneralService} from "@services/general.service";
import logoutIcon from '@iconify/icons-mdi-light/logout';
import profileIcon from '@iconify/icons-mdi-light/account';
import clipboardPlusIcon from '@iconify/icons-mdi-light/clipboard-plus';
import helpIcon from '@iconify/icons-mdi-light/help-circle';
import {AuthService} from "@services/auth.service";
import {LangDirection} from "@const/lang.enums";
import {isString} from "@helper-functions/is-string";
import {MatDialog} from "@angular/material/dialog";
import {UserProfileComponent} from "@pages/user-profile/user-profile.component";
import {VideoLearningComponent} from "@extension-components/video-learning/video-learning.component";
import {Popover} from "@widgets/popover/popover.service";
import {AppConfigService} from "@services/app-config.service";
import {TemplateSelectComponent} from "@pages/templates/template.select.component";
import {AppUser} from "@models/app-user";
import {DestroyObserver} from "@app/abstracts/destroy-observer";

@Component({
  selector: 'compose-ai',
  template: `

    <ng-template #messagesList>
      <div fxLayout="column" class="message-suggestion p-10" *ngFor="let msg of messages">
        <div class="fc fr">
                <span style="text-align: left;font-size: 14px;" [innerHtml]="msg.message | trustHtml">
                </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" class="msg-btns">
          <ng-container *ngIf="options.translate">
            <lang-select #target></lang-select>
            <button *ngIf="options.translate" [disabled]="!target.langCtl?.value" (click)="translate(msg, target)"
                    mat-icon-button class="">
              <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" color="#4284f3"
                       [icon]="translateIcon"></ic-icon>
            </button>
          </ng-container>
          <button *ngIf="options.copy" (click)="selected(msg.message)" mat-icon-button class="">
            <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" color="#4284f3" [icon]="copyIcon"></ic-icon>
          </button>
          <button style="color:#4284f3;" *ngIf="options.add" (click)="addMessage(msg.message)" mat-flat-button class="">
            insert
            <!--            <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" color="#4284f3" [icon]="plusIcon"></ic-icon>-->
          </button>
        </div>
        <div fxLayout="column" *ngIf="msg.translate">
          <div class="m-t-10" [style.direction]="msg.direction" [innerHtml]="msg.translate | trustHtml"></div>
          <div fxLayout="row" fxLayoutAlign="end center" class="msg-btns">
            <button *ngIf="options.copy" (click)="selected(msg.translate)" mat-icon-button class="">
              <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" color="#4284f3"
                       [icon]="copyIcon"></ic-icon>
            </button>
            <button style="color:#4284f3;" *ngIf="options.add" (click)="addMessage(msg.translate)" mat-flat-button
                    class="">
              insert
              <!--              <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" color="#4284f3" [icon]="plusIcon"></ic-icon>-->
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <div [style.min-width]="options.appMinWidth" class="rounded-border"
         *ngIf="options && !options.hide && ((options.viewOnly && !loading) || this.messages.length) || !this.options || !this.options.viewOnly">
      <div *ngIf="!options.noHeader" fxLayout="row" fxLayoutAlign="start center" class="bg-primary p-5 logout-box">
        <div class="compose-title">{{title}}</div>
        <div class="pull-r" fxLayout="row" fxLayoutAlign="end end">
          <button *ngIf="this.options && this.options.url" (click)="viewHelp()" mat-icon-button class="pull-r">
            <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" style="color: darkblue !important;"
                     color="darkblue" [icon]="helpIcon"></ic-icon>
          </button>
          <button *ngIf="this.options && this.options.showTmp && activeFeatures?.showTmp"
                  (click)="tmpSelect($event.currentTarget)" mat-icon-button class="pull-r">
            <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" style="color: darkblue !important;"
                     color="darkblue" [icon]="clipboardPlusIcon"></ic-icon>
          </button>
          <button (click)="viewProfile()" mat-icon-button class="pull-r">
            <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" style="color: darkblue !important;"
                     color="darkblue" [icon]="profileIcon"></ic-icon>
          </button>
          <!--          <button (click)="logout()" mat-icon-button class="pull-r">-->
          <!--            <ic-icon fxLayout="row" fxLayoutAlign="center center" size="20" style="color: darkblue !important;"-->
          <!--                     color="darkblue" [icon]="logoutIcon"></ic-icon>-->
          <!--          </button>-->
        </div>
      </div>
      <div fxLayout="column" [style.max-height]="innerHeight"
           class="gpt3-suggest-message suggets-panel gmail-menu-shadow p-10">
        <div class="" [formGroup]="form">
          <div fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="0" fxLayout.xs="column" fxLayoutAlign="center center"
               fxLayoutAlign.xs="start start" class="settings-row">

            <div *ngIf="form.controls.tone" fxLayout="row" class="fw fr m-b-20" fxLayoutAlign="center center">
              <div class="field-name">
                Tone
              </div>
              <gpt3-tone-select [maxWidth]="null" class="fw" formControlName="tone"></gpt3-tone-select>
            </div>
          </div>
          <div *ngIf="form.controls.to" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
               fxLayoutAlign="center center" class="m-b-20">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center" class="fw">
              <div class="field-name">Audience</div>
              <div class="fw toolbar-input toolbar-input-border fc fr">
                <mat-select (openedChange)="onOpen()" (focus)="onOpen()" formControlName="to">
                  <mat-option *ngFor="let t of to;" [value]="t">
                    {{t}}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="fw" *ngIf="form.value.to === 'Other (free text)'">
              <input formControlName="toOther"
                     class="fw toolbar-input toolbar-input-border fc fr"/>
            </div>
          </div>

          <div *ngIf="form.controls.action" fxLayout="row" fxLayoutAlign="center center" class="fw m-b-20">
            <div class="field-name">
              Action
            </div>
            <div class="fw toolbar-input toolbar-input-border fc fr">
              <mat-select (openedChange)="onOpen()" (focus)="onOpen()" formControlName="action">
                <mat-option *ngFor="let messageType of messageTypes;" [value]="messageType">
                  {{messageType}}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div *ngIf="form.controls.templateSubjects" fxLayout="row" fxLayoutAlign="center center" class="fw m-b-20">
            <div class="field-name">
              Template
            </div>
            <div class="fw toolbar-input toolbar-input-border fc fr">
              <mat-select (openedChange)="onOpen()" (focus)="onOpen()" formControlName="templateSubjects">
                <mat-option *ngFor="let templateSubject of templateSubjects;" [value]="templateSubject">
                  {{templateSubject}}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div *ngFor="let cf of customFields">
            <div class="fw">
              <div class="field-name">
                {{cf.label}}
              </div>
              <input [formControlName]="cf.key"
                     class="fw toolbar-input toolbar-input-border fc fr"/>
            </div>
          </div>


          <div *ngIf="form.controls.about && options && options.about" fxLayout="column" class="fw m-b-20">
            <div class="fr m-b-10">
              <div class="field-name m-r-5 m-t-5">
                {{labels.description}}
              </div>
              <mat-slide-toggle *ngIf="!options || !options.noBulletPoints" formControlName="isBullets"
                                class="about-type pull-r"><span
                class="toogle-title">{{form.value.isBullets ? 'Bullet points' : 'Summary'}}</span></mat-slide-toggle>
            </div>
            <div class="fc fr fw">
                    <textarea bulletpoint-area
                              [activeBullets]="form.value.isBullets"
                              formControlName="about" rows="5"
                              style="min-height: 90px;text-align: left;"
                              class="p-10 fw toolbar-input toolbar-input-border"
                              matInput></textarea>
            </div>
          </div>
        </div>

        <!--        MESSAGES LIST-->
        <div fxLayout="column" fxLayoutAlign="center center" class="m-t-10" *ngIf="!loading && messages.length">
          <div class="fw hello" [style.min-width.px]="options.minWidth" [style.max-width.px]="options.maxWidth || 400"
               style="white-space: normal;">
            <ng-container [ngTemplateOutlet]="messagesList">
            </ng-container>
          </div>
        </div>
        <!--        MESSAGES LIST-->
      </div>
      <div class="p-10" fxLayout="row">
        <button *ngIf="options.suggestImages && activeFeatures.suggestImages" [style.margin-right.px]="15"
                [disabled]="imageIsLoading" class="msg-add-btn bg-primary" mat-stroked-button
                (click)="createImage()">
          <loading-button [colorBlue]="false" [title]="'Create Image'" [loading]="imageIsLoading"></loading-button>
        </button>
        <!--        <div style="flex-grow: 1" fxLayout="row" [formGroup]="form" *ngIf="options && options.suggestImages">-->
        <!--          <div class="flex-center m-r-10">-->
        <!--            <mat-checkbox class="example-margin" formControlName="imgAboutEnable">add description</mat-checkbox>-->
        <!--          </div>-->
        <!--          <div class="fw" *ngIf="form.value.imgAboutEnable">-->
        <!--            <input-->
        <!--              formControlName="imgAboutEnable"-->
        <!--              class="fw toolbar-input toolbar-input-border fc fr"/>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <div *ngIf="images && images.length" style="padding-top: 10px;max-width: 400px;margin: auto auto;" fxLayout="row">
        <div style="width: 50%;padding: 5px;" *ngFor="let image of images">
          <div (click)="addImage(image)">
            <img [src]="image.url" class="fw"/>
          </div>
          <!--          <a *ngIf="options.downloadImage" [href]="image.url" download="image512X512.png"> Download Image </a>-->
        </div>
      </div>


      <div fxLayoutAlign="end" fxLayout="row" class="p-15">
        <ng-container
          *ngIf="options.continue && options.continue.addLabel && ((form.value.about && form.value.about.length > 300) || (messages.length && addClicked))"
          fxLayoutAlign="end" fxLayout="row" class="p-15">
          <button [style.margin-right.px]="15" *ngIf="messages && messages.length && !options.add" [disabled]="loading"
                  class="msg-add-btn bg-primary" mat-stroked-button
                  (click)="continueAdd()">
            {{options.continue.addLabel}}
          </button>

          <button style="margin-right: 15px;" color="primary" [disabled]="loading" class="msg-generate-btn"
                  mat-stroked-button (click)="continue()">
            <loading-button [title]="options.continue.label || 'Continue'" [loading]="loading"></loading-button>
          </button>
        </ng-container>

        <ng-container *ngIf="(options.showGenerate || options.showAdd) && !isContinue">
          <button [style.margin-right.px]="options.showGenerate ? 15 : 0"
                  *ngIf="options.showAdd && messages && messages.length && !isContinue" [disabled]="loading"
                  class="msg-add-btn bg-primary" mat-stroked-button
                  (click)="add()">
            Add
          </button>
          <button *ngIf="options.showGenerate" color="primary" [disabled]="loading" class="msg-generate-btn"
                  mat-stroked-button (click)="generate()">
            <loading-button [title]="regenerate && messages.length ? 'Regenerate' : 'Generate'"
                            [loading]="loading"></loading-button>
          </button>
        </ng-container>
      </div>
    </div>
  `,
  styles: [`
    :host .toolbar-input {
      width: 100%;
      min-width: 120px;
      height: 35px;
    }

    .logout-box {
      padding: 8px;
      background: aliceblue;
      text-transform: capitalize;
      color: grey;
    }

    .msg-btns {
      transition: all 0.5s ease;
      display: none !important;
      opacity: 0;
    }

    .message-suggestion:hover .msg-btns {
      transition: all 0.5s ease;
      display: flex !important;
      opacity: 1;
    }

    :host ::ng-deep mat-list-option .mat-list-item-content {
      padding: 0 !important;
    }

    .gpt3-suggest-message.suggets-panel {
      box-sizing: border-box;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      transform: translateY(0);
      transition-timing-function: cubic-bezier(0, 0, .2, 1);
      transition-duration: 167ms;
      color: grey;
      font-family: sans-serif;
      font-size: 14px;
      background: white;
    }

    .toogle-title {
      color: grey;
      font-family: sans-serif;
      font-size: 12px;
    }

    .about-type {
      display: flex;
      align-items: center;
    }

    .message-suggestion {
      height: auto !important;
      margin-bottom: 5px;
      padding-top: 10px !important;
      padding-bottom: 15px !important;
      border: solid 1px #438ce56b;
      border-radius: 8px;
    }

    .field-name {
      font-family: sans-serif;
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      min-width: 75px;
      margin-right: 10px;
      Padding-left: 7px
    }

    .preview-variable {
      display: inline-block !important;
      background: #4284f3;
      color: white;
      padding: 3px 7px;
      border-radius: 4px;
      box-sizing: border-box;
      max-width: 170px;
      font-size: 14px;
      text-align: center;
    }

    :host textarea {
      overflow: auto;
    }

    .gpt3-suggest-message {
      color: black;
      height: 100%;
      overflow: auto;
    }
  `],
})
export class ComposeAiComponent extends DestroyObserver implements OnDestroy, OnInit, AfterViewInit {
  customFields = [];
  innerHeight = '100%';
  state: any = {};
  regenerate = false;
  clipboardPlusIcon = clipboardPlusIcon;
  emailIcon = emailIcon;
  profileIcon = profileIcon;
  helpIcon = helpIcon;
  logoutIcon = logoutIcon;
  translateIcon = translateIcon;
  plusIcon = plusIcon;
  copyIcon = copyIcon;
  addClicked = false;
  loading = false;
  labels = {
    description: 'Description',
  };
  images = [];
  imageIsLoading = false;
  isContinue = false;
  activeFeatures: any = {}
  @Input() wideMode = true;
  @Input() title = 'Generate';
  @Input() options: any = {};
  @Input() info: any = {};
  @Input() apiCallback = (info: any) => of(info);
  @Input() validatorCallback = null;
  @Input() message = '';
  @Input() cacheId = '';
  @Input() overlayRef;
  @Output() loadingState = new EventEmitter();
  @Output() onMessageSelected = new EventEmitter();
  messages: any = []; //[{message: 'hello how are you doing today mr hello >'}];

  form = new UntypedFormGroup({
    settings: new UntypedFormControl({}),
    platform: new UntypedFormControl(''),
    operation: new UntypedFormControl('')
  })

  to = ['Colleagues', 'Partners', 'Customers', 'Potential Customers', 'Other (free text)'];

  messageTypes = [
    'Promoting',
    'Announcing',
    'Recommending',
    'Reminding of',
    'Telling a story about',
    'Inquiring about',
    'Suggesting',
    'Reviewing',
    'Reporting about',
    'Offering',
    'Selling',
    'Whishing'
  ];

  templateSubjects = [];

  constructor(private textAiService: TextAiService,
              private generalService: GeneralService,
              private authService: AuthService,
              private appConfig: AppConfigService,
              private dialog: MatDialog,
              private popupService: Popover) {
    super();
    this.appConfig.config$
      .pipe(this.notifier$)
      .subscribe((config: any) => {
        this.templateSubjects = config.editor.templateSubjects;
      });
    this.authService.user$.pipe(this.notifier$)
      .subscribe((user: AppUser) => {
        if (user && user.uid) {
          this.activeFeatures = user.getFeatures();
        }
      });
  }

  tmpSelect(target) {
    import('../../../app/pages/templates/template.select.module').then(m => m.TemplateSelectModule)
    const ref = this.popupService.open({
      content: TemplateSelectComponent,
      origin: target,
      data: {
        outputs: {
          onEvent: (template) => {
            this.loadingState.emit({name: 'tmp-succeed', message: template.text, success: true});
            // this.selected(template.text);
            ref.close();
            this.popupService.close();
          }
        }
      }
    });
  }

  async addImage(image: any) {
    if (!this.options.downloadImage) {
      this.loadingState.emit({name: 'image-selected', image, success: true});
    } else {
      // const response = await fetch(image.url, { mode: 'no-cors'});
      // const blob = await response.blob();
      // debugger
      // console.log(blob);
      // const url = window.URL.createObjectURL(blob);
      // const a: any = document.createElement('a');
      // a.style.display = 'none';
      // a.href = url;
      // // the filename you want
      // a.download = 'image512x512.png';
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
    }
  }

  initOptions() {
    this.options = Object.assign({
      helpUrl: null,
      minWidth: 350,
      showAdd: true,
      customBtn: null,
      showGenerate: true,
      noHeight: false,
      noHeader: false,
      showTmp: true,
      viewOnly: false,
      copy: false,
      add: false,
      translate: false,
      tone: false,
      audience: false,
      action: false,
      about: false,
      noBulletPoints: false,
      hide: false,
      templateSubjects: false,
    }, this.options || {}, this.info && this.info.options || {});
    if (this.options.labels) {
      this.labels = Object.assign({}, this.labels, this.options.labels)
    }
  }

  getOption(key) {
    return this.options && this.options[key];
  }

  onOpen() {
    this.generalService.addClassToOverlay('writi-root');
  }


  enableBetaFeatures() {
    if (this.activeFeatures && this.activeFeatures.suggestImages) {
      this.form.addControl('imgAbout', new UntypedFormControl(''));
      this.form.addControl('imgAboutEnable', new UntypedFormControl(this.info && this.info.imgAboutEnable || ''));
    }

    if (this.activeFeatures && this.activeFeatures.showTmp && this.getOption('templateSubjects')) {
      this.form.addControl('templateSubjects', new UntypedFormControl(this.info && this.info.templateSubject || ''))
    }
  }

  ngOnInit(): void {
    this.initOptions();
    if (!this.getOption('noHeight')) {
      this.innerHeight = (window.innerHeight - 120) + 'px';
    }

    if(this.options && this.options.customFields && this.options.customFields.length) {
      this.customFields = this.options.customFields;
      this.options.customFields.forEach(({key, label})=> {
        this.form.addControl(key, new FormControl());
      });
    }
    this.cacheId = this.info && this.info.uuid;
    const {value, messages} = this.getCachePayload();

    if (messages && messages.length) {
      this.messages = messages;
      this.loadingState.emit({name: 'succeed', ...this.state, messages: this.messages});
    }

    if (this.getOption('tone')) {
      this.form.addControl('tone', new UntypedFormControl(value && value.tone || this.info && this.info.tone || ''));
    }

    if (this.getOption('action')) {
      this.form.addControl('action', new UntypedFormControl(value && value.action || this.info && this.info.action || ''));
    }

    if (this.getOption('audience')) {
      this.form.addControl('to', new UntypedFormControl(value && value.to || this.info && this.info.to || ''));
      this.form.addControl('toOther', new UntypedFormControl(value && value.toOther || this.info && this.info.toOther || ''))
    }

    if (this.getOption('about')) {
      this.form.addControl('about', new FormControl(value && value.about || this.info && this.info.about || ''));
      this.form.addControl('isBullets', new UntypedFormControl(value && value.isBullets || this.info && this.info.isBullets || ''));
    }

    if (this.info && this.info.platform) {
      this.form.get('platform').patchValue(this.info.platform);
    }

    if (this.info && this.info.operation) {
      this.form.get('operation').patchValue(this.info.operation);
    }

    this.enableBetaFeatures();

    if (this.info && this.info.meta) {
      for (const k in this.info.meta) {
        this.form.addControl(k, new UntypedFormControl(this.info.meta[k]));
      }
    }

    if (this.validatorCallback && !this.validatorCallback(this.form.value)) {
      if (this.overlayRef) {
        this.overlayRef.dispose();
      }
      return;
    }

    if (this.getOption('viewOnly') && !messages.length) {
      this.generate();
    } else {
      this.form.valueChanges.subscribe((value) => {
        this.state.value = value;
        console.log('update cache....');
        this.textAiService.setResults(this.cacheId, {value}, true);
      });
      // if (this.cacheId) {
      //   const state = this.textAiService.getResults(this.cacheId);
      //   if (state) {
      //     if (state.messages) {
      //       this.messages = state.messages;
      //     }
      //     if (state.value) {
      //       this.form.patchValue(state.value, {emitEvent: false});
      //       if(this.form.get('about')) {
      //         console.log('SET ABOUT FORM', this.form.value);
      //         this.form.get('about').patchValue(state.value && state.value.about || '')
      //       }
      //       console.log('CACHE FORM', this.form.value);
      //     }
      //   }
      // }
    }
  }

  getCachePayload() {
    let state: any = {value: {}, messages: []};
    if (this.cacheId) {
      const cache = this.textAiService.getResults(this.cacheId);
      if (cache) {
        if (cache.messages) {
          state.messages = cache.messages || [];
        }
        if (cache.value) {
          state.value = cache.value || {};
        }
        // if (state.value) {
        //   this.form.patchValue(state.value, {emitEvent: false});
        //   if(this.form.get('about')) {
        //     console.log('SET ABOUT FORM', this.form.value);
        //     this.form.get('about').patchValue(state.value && state.value.about || '')
        //   }
        //   console.log('CACHE FORM', this.form.value);
        // }
      }
    }
    return state;
  }

  selected(message) {
    this.loadingState.emit({name: 'copy', message, success: false});
    if (this.getOption('copy')) {
      navigator.clipboard.writeText(message.replaceAll('<br>', '\n')).then((c) => {
        this.generalService.notification('Message copied to clipboard', 'COPY');
      });
      this.popupService.close();
    }
  }

  continue() {
    this.isContinue = true;
    this.loading = true;
    this.options.add = true;
    const value = this.form.value;
    this.textAiService.continueText(value, {})
      .pipe(take(1))
      .subscribe((res) => {
        if (res.messages) {
          this.messages = (res && res.messages || []).map((message) => {
            return {message: message.replaceAll('\n', '<br>').trim()};
          });
        }

        if (this.messages.length === 1) {
          this.state = {message: this.messages[0], type: value.type};
        } else {
          this.state = {messages: this.messages, type: value.type};
        }
        this.loading = false;
        this.loadingState.emit({
          name: 'continue-text-success',
          success: true,
          messages: this.messages,
          message: res.message
        });
        console.log(res);
      })
  }

  createImage() {
    this.imageIsLoading = true;
    this.textAiService.generateImages(this.form.value)
      .pipe(take(1))
      .subscribe((res) => {
        this.images = res && res.imgObj || [];
        this.imageIsLoading = false;
        this.loadingState.emit({name: 'image-loaded', images: this.images, success: true});
      });
  }

  generate() {
    this.regenerate = true;
    this.isContinue = false;
    this.loadingState.emit({name: 'loading', success: true});
    const value = this.form.value;

    this.loading = true;
    this.apiCallback(this.form.value)
      .pipe(take(1))
      .subscribe((res) => {
        console.log('resppp', res);
        if (res.messages) {
          this.loadingState.emit({name: 'succeed', messages: res.messages, success: true});
          this.messages = (res && res.messages || []).map((message) => {
            return {message: message.replaceAll('\n', '<br>').trim()};
          }).filter((m) => !(m.message.includes('<br>') && m.message.length < 9));
          if (this.messages.length === 1) {
            this.state = {message: this.messages[0], type: value.type};
          } else {
            this.state = {messages: this.messages, type: value.type};
          }
        } else if (res.message) {
          this.messages = [{message: res.message.replaceAll('\n', '<br>').trim()}];
          this.state = {message: res.message, type: value.type, value: this.form};
          this.loadingState.emit({name: 'succeed', success: true, messages: res.messages, message: res.message});
        } else {
          this.loadingState.emit({name: 'failed-to-load', success: false, code: res.code});
        }
        this.state.value = this.form.value;
        if (this.messages.length) {
          this.state.messages = this.messages;
        }
        if (this.cacheId) {
          this.textAiService.setResults(this.cacheId, this.state);
        }
        this.loading = false;
      }, (e) => {
        this.loadingState.emit({name: 'failed', success: false});
      });
  }

  addMessage(message) {
    const name = this.isContinue ? 'add-continue' : 'add';
    this.loadingState.emit({name, message, success: false});
    this.addClicked = true;
    this.popupService.close();
  }

  continueAdd() {
    this.state.messages = this.state.messages.map(m => isString(m) ? m : m.message);
    if (this.state.message) {
      this.state.message = !isString(this.state.message) ? this.state.message.message : this.state.message;
    }
    this.onMessageSelected.emit({name: 'continue-add', ...this.state});
    this.loadingState.emit({name: 'continue-add', ...this.state});
  }

  add() {
    this.state.messages = this.state.messages.map(m => isString(m) ? m : m.message);
    if (this.state.message) {
      this.state.message = !isString(this.state.message) ? this.state.message.message : this.state.message;
    }
    this.onMessageSelected.emit({name: 'add', ...this.state});
    this.loadingState.emit({name: 'add', ...this.state});
    this.addClicked = true;
    this.popupService.close();
  }

  translate(message, langComp) {
    const target = langComp.langCtl.value;
    this.textAiService.translate({about: message.message, target})
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp && resp.success && resp.message) {
          message.translate = resp && resp.message.replaceAll('\n', '<br>');
          message.direction = LangDirection[target] || 'ltr';
          console.log('translate', resp);
        }
      })
  }

  ngAfterViewInit(): void {
    try {
      this.overlayRef.updatePosition();
    } catch (e) {

    }
  }

  logout() {
    this.authService.SignOut().then(() => {
      location.reload();
    });
  }

  viewHelp() {
    this.dialog.open(VideoLearningComponent, {
      panelClass: ['writi-root', 'no-padding'],
      backdropClass: 'no-padding',
      data: {
        ex: true,
        url: this.options.url
      }
    });
  }

  viewProfile() {
    this.dialog.open(UserProfileComponent, {
      panelClass: ['writi-root', 'no-padding'],
      backdropClass: 'no-padding',

      width: '600px',
      data: {
        ex: true
      }
    });
  }

  ngOnDestroy(): void {
  }

}

