import {Component, Input, OnInit} from '@angular/core';
import {ASSETS_URL} from "@const/global.enums";

@Component({
  selector: 'loading-button',
  template: `
    <div>
      <span *ngIf="!loading">{{title}}</span>
      <img *ngIf="loading"
           class="loader"
           [src]="loaderUrl"/>
    </div>
  `,
  styles: [`
    .loader {
      width: 100%;
      max-width: 30px;
    }
  `]
})
export class LoadingButton implements OnInit {
  @Input() title;
  @Input() loading = false;
  @Input() colorBlue = true;
  loaderUrl = `https://writi.io/wp-content/uploads/2022/06/dots-loader.svg`;
  constructor() {
  }

  ngOnInit() {
    if(!this.colorBlue) {
      this.loaderUrl = `https://writi.io/wp-content/uploads/2022/06/dots-loader-white.svg`;
    }
  }

}
