import {ApplicationRef, EnvironmentInjector, Injectable, createComponent, Type} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DomService {

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector//EnvironmentInjector
  ) { }

  add(comp: Type<any>, inputs = {}) {
    // Create a `ComponentRef` instance.
    const compRef = createComponent(comp, {
      environmentInjector: this.injector
    });

    if(inputs) {
      for(const k in inputs) {
        compRef.setInput(k, inputs[k]);
      }
    }

    document.body.appendChild(compRef.location.nativeElement);

    // Register the newly created ref using the `ApplicationRef` instance
    // to include the component view into change detection cycles.
    this.appRef.attachView(compRef.hostView);

    return {
      compRef,
      destroy: ()=> {
        this.appRef.detachView(compRef.hostView);
        compRef.destroy();
      }
    }
  }

  remove() {

  }
}
