import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TextAiService} from "@services/text-ai.service";
import {ReCaptchaV3Service} from "ngx-captcha";
import emailIcon from '@iconify/icons-mdi-light/email';
import {environment} from "@environment";
import {GeneralService} from "@services/general.service";

@Component({
  selector: 'follow-up-widget',
  template: `
    <form [formGroup]="form" class="writi-root fcol fc fw m-t-10">
        <div class="fcol fc fw toolbar-input-border">
          <img style="width: 100%;" src="https://writi.io/wp-content/uploads/2022/02/email-header.png"/>
          <textarea formControlName="message" class="subject-message p-15 fw"></textarea>
          <div class="fr fc generate p-15">
            <div class="subject-counter">
              {{form.value.message.length || 0}} / 350
            </div>
            <button class="generate-btn" (click)="suggest()" mat-flat-button color="primary">
              <loading-button title="Generate" [colorBlue]="false" [loading]="loading"></loading-button>
            </button>
          </div>
        </div>

        <div class="fcol m-t-20" *ngIf="followup">
          <mat-selection-list [multiple]="false" style="width: 100%;">
            <mat-list-option class="message-suggestion" (click)="copy()">
              <span style="font-size: 14px;text-align: left !important;padding: 20px;" [innerHtml]="followup | trustHtml"></span>
            </mat-list-option>
          </mat-selection-list>

<!--          <span style="font-size: 20px;" [innerHtml]="followup | trustHtml"></span>-->
        </div>
    </form>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;
    }
    :host .subject-message {
      border: none;
      resize: none;
      min-height: 140px;
      overflow: auto;
      outline: none !important;
      /*border-radius: 12px;*/
      /*border: solid 1px #d6d8de;*/
    }
    :host .subject-line {
      font-size: 16px;
    }
    .subject-counter {
      margin-bottom: -8px;
      margin-right: auto;
    }
    .generate {
      border-top: solid 2px #f6f7f8;
      background: white;
    }
    .generate-btn {
      margin-left: auto;
      font-size: 18px;
      /*min-width: 220px;*/
      /*font-size: 20px;*/
      /*line-height: 52px !important;*/
    }
    .loader {
      width: 100%;
      max-width: 30px;
    }

    .message-suggestion {
      height: auto !important;
      margin-bottom: 5px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  `]
})
export class FollowUpWidget implements OnInit {
  loading = false;
  siteKey = environment.reCaptchaKey;
  emailIcon = emailIcon;
  form = new UntypedFormGroup({
    message: new UntypedFormControl('', Validators.minLength(10)),
    recaptcha: new UntypedFormControl('', Validators.required)
  });
  followup = '';
  @Input() descRequest = true;
  @Input() public  = true;
  @Output() messageGenerated = new EventEmitter();
  constructor(private textAiService: TextAiService,
              private generalService: GeneralService,
              private reCaptchaV3Service: ReCaptchaV3Service) {

  }

  ngOnInit() {
  }

  selected(event) {
    navigator.clipboard.writeText(event);
    this.generalService.notification('Follow up copied to clipboard.', 'COPY');
  }

  handleReset() {
    console.log('reset');
  }

  handleExpire() {
    console.log('handleExpire');
  }

  handleLoad() {
    console.log('handleLoad');
  }

  handleSuccess(ev) {
    console.log('handleLoad', ev);
  }

  async suggest() {
    this.loading = true;
    let token = '';
    if(this.public) {
      token = await this.reCaptchaV3Service.executeAsPromise(this.siteKey, 'homepage', {
        useGlobalDomain: false
      });
    }
    const {message} = this.form.value;
    if(message && message.length > 9) {
      let observer: any = null;
      if(this.descRequest) {
        observer = this.textAiService.suggestByDescriptionUpPublic(message, '', {}, token);
      } else {
        observer = this.public ? this.textAiService.suggestFollowUpPublic(message, '', {}, token) : this.textAiService.suggestFollowUp({message}, {});
      }
      observer.subscribe((response)=> {
       console.log(response);
        this.followup = response.message || '';
        this.loading = false
        this.messageGenerated.next(response);
      })
    } else {
      this.loading = false;
      this.generalService.notification('Min message length is 10 characters.', 'Message');
    }
  }

  copy() {
    // @ts-ignore
    navigator.clipboard.writeText(this.followup.replaceAll('<br>', '\n')).then((c)=> {
      this.generalService.notification('Message copied to clipboard', 'COPY');
    });
  }

}
