import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {LoadingButton} from "@widgets/loading-button/loading-button";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule
  ],
  providers: [],
  declarations: [LoadingButton],
  exports: [LoadingButton]
})
export class LoadingButtonModule { }
