export function isProvider(provider) {
  return window.origin.includes(provider);
}


export function getPlatformByUrl() {
  let platform = 'generic';
  if(isProvider('mail.yahoo.com')) {
    platform = 'yahoo';
  }
  if(isProvider('mail.google.com')) {
    platform = 'gmail';
  }
  if(isProvider('linkedin.com')) {
    platform = 'linkedin';
  }
  if(isProvider('outlook.live')) {
    platform = 'outlook';
  }
  return platform;
}
