import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TextAiService} from "@services/text-ai.service";
import {ReCaptchaV3Service} from "ngx-captcha";
import emailIcon from '@iconify/icons-mdi-light/email';
import {environment} from "@environment";
import {GeneralService} from "@services/general.service";

@Component({
  selector: 'subject-line-widget',
  template: `
    <form [formGroup]="form" class="fcol fc fw m-t-10">
        <div class="fcol fc fw toolbar-input-border">
          <img class="fw" src="https://writi.io/wp-content/uploads/2022/02/email-header.png"/>
          <textarea formControlName="message" class="subject-message p-15 fw"></textarea>
          <div fxLayout="row" fxLayoutAlign="center end" class="generate p-15">
            <div class="subject-counter">
              {{form.value.message.length || 0}} / 350
            </div>
            <button class="generate-btn pull-r" (click)="suggest()" mat-flat-button color="primary">
              <loading-button title="Generate" [colorBlue]="false" [loading]="loading"></loading-button>
            </button>
          </div>
        </div>

        <div class="fcol m-t-20">
          <button mat-raised-button (click)="selected(subjectLine)" class="subject-line m-b-10 p-15" *ngFor="let subjectLine of subjectLines" [value]="subjectLine">
            <div class="fr">
              <!--            <ic-icon class="m-r-5" style="display: flex;align-items: center;font-size: 30px" [icIcon]="emailIcon"></ic-icon>-->
              <span style="font-size: 20px;" [innerHtml]="subjectLine | trustHtml"></span>
            </div>
          </button>
        </div>
<!--      <ngx-recaptcha2 #captchaElem-->
<!--                      [siteKey]="siteKey"-->
<!--                      (reset)="handleReset()"-->
<!--                      (expire)="handleExpire()"-->
<!--                      (load)="handleLoad()"-->
<!--                      (success)="handleSuccess($event)"-->
<!--                      [useGlobalDomain]="false"-->
<!--                      [hl]="'en'"-->
<!--                      [type]="'image'"-->
<!--                      formControlName="recaptcha">-->
<!--      </ngx-recaptcha2>-->
    </form>
  `,
  host: {
    class: 'writi-root'
  },
  styles: [`
    :host {
      display: flex;
      width: 100%;
    }
    :host .subject-message {
      border: none;
      resize: none;
      min-height: 140px;
      overflow: auto;
      outline: none !important;
      /*border-radius: 12px;*/
      /*border: solid 1px #d6d8de;*/
    }
    :host .subject-line {
      font-size: 16px;
    }
    :host .generate {
      background: white;
    }
    .subject-counter {
      margin-bottom: -8px;
      margin-right: auto;
    }
    .generate-btn {
      margin-left: auto;
      font-size: 18px;
      padding: 5px 10px;
      /*min-width: 220px;*/
      /*font-size: 20px;*/
      /*line-height: 52px !important;*/
    }
    .loader {
      width: 100%;
      max-width: 30px;
    }
  `]
})
export class SubjectLineWidget implements OnInit {
  loading = false;
  siteKey = environment.reCaptchaKey;
  emailIcon = emailIcon;
  form = new UntypedFormGroup({
    message: new UntypedFormControl('', Validators.minLength(10)),
    recaptcha: new UntypedFormControl('', Validators.required)
  });
  subjectLines = [];
  @Input() public  = true;
  @Input() set msg(message: string) {
    this.form.get('message').patchValue(message);
  }
  constructor(private textAiService: TextAiService,
              private generalService: GeneralService,
              private reCaptchaV3Service: ReCaptchaV3Service) {

  }

  ngOnInit() {
  }

  selected(event) {
    navigator.clipboard.writeText(event);
    this.generalService.notification('Subject line copied to clipboard.', 'COPY');
  }

  handleReset() {
    console.log('reset');
  }

  handleExpire() {
    console.log('handleExpire');
  }

  handleLoad() {
    console.log('handleLoad');
  }

  handleSuccess(ev) {
    console.log('handleLoad', ev);
  }

  async suggest() {
    this.loading = true;
    let token = '';
    if(this.public) {
      token = await this.reCaptchaV3Service.executeAsPromise(this.siteKey, 'homepage', {
        useGlobalDomain: false
      });
    }
    const {message} = this.form.value;
    if(message && message.length > 9) {
      const observer = this.public ? this.textAiService.suggestSubjectLinePublic(message, '', {}, token) : this.textAiService.suggestSubjectLine({message});
      observer.subscribe((response)=> {
       console.log(response);
        this.subjectLines = response.messages || [];
        this.loading = false
      })
    } else {
      this.loading = false;
      this.generalService.notification('Min message length is 10 characters.', 'Message');
    }
  }

}
