import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TextAiService} from "@services/text-ai.service";
import {ReCaptchaV3Service} from "ngx-captcha";
import emailIcon from '@iconify/icons-mdi-light/email';
import {environment} from "@environment";
import {GeneralService} from "@services/general.service";
import {switchMap, take} from "rxjs/operators";
import {from} from "rxjs";

@Component({
  selector: 'linkedin-widget',
  template: `
    <compose-ai *ngIf="selectedGenerator"
                [info]="selectedGenerator.info"
                [options]="selectedGenerator.options"
                [apiCallback]="selectedGenerator.callback"></compose-ai>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;
    }

    :host .subject-message {
      border: none;
      resize: none;
      min-height: 140px;
      overflow: auto;
      outline: none !important;
      /*border-radius: 12px;*/
      /*border: solid 1px #d6d8de;*/
    }

    :host .subject-line {
      font-size: 16px;
    }

    .subject-counter {
      margin-bottom: -8px;
      margin-right: auto;
    }

    .generate {
      border-top: solid 2px #f6f7f8;
      background: white;
    }

    .generate-btn {
      margin-left: auto;
      font-size: 18px;
      /*min-width: 220px;*/
      /*font-size: 20px;*/
      /*line-height: 52px !important;*/
    }

    .loader {
      width: 100%;
      max-width: 30px;
    }

    .message-suggestion {
      height: auto !important;
      margin-bottom: 5px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  `]
})
export class LinkedinWidget implements OnInit {
  loading = false;
  promptsMap = {
    'linkedinPost': {
      key: 'linkedinPost',
      widget: 'compose-ai',
      info: {
        operation: 'linkedinPost',
        platform: 'Linkedin'
      },
      options: {
        // customFields: [
        //   {label: 'Title', key: 'title'},
        //   {label: 'Company', key: 'company'},
        // ],
        operation: 'linkedinPost',
        noHeader: true,
        tone: true,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },
    'facebookPost': {
      key: 'linkedinPost',
      widget: 'compose-ai',
      info: {
        operation: 'facebookPost',
        platform: 'Facebook'
      },
      options: {
        operation: 'facebookPost',
        noHeader: true,
        tone: true,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },
    'jobDescription': {
      key: 'jobDescription',
      widget: 'custom',
      info: {
        operation: 'jobDescription'
      },
      options: {
        about: true,
        copy: true,
        noHeader: true,
        tone: true,
        customFields: [
          {label: 'Title', key: 'role'},
          {label: 'Company', key: 'company'},
        ],
      },
      callback: (info) => this.suggest(info)
    },
    'linkedinBio': {
      key: 'linkedinBio',
      widget: 'compose-ai',
      info: {
        operation: 'linkedinBio'
      },
      options: {
        //title
        customFields: [
          {label: 'Title', key: 'title'},
          { label: 'Industry', key: 'industry'},
        ],
        noHeader: true,
        hide: false,
        viewOnly: false,
        tone: true,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },

    'jobRecommendation': {
      key: 'jobRecommendation',
      widget: 'compose-ai',
      info: {
        operation: 'jobRecommendation'
      },
      options: {
        customFields: [
          {label: 'Name', key: 'fullName'},
          { label: 'Relationship', key: 'relationship'},
          { label: 'Position', key: 'prevJob'},
        ],
        noHeader: true,
        hide: false,
        viewOnly: false,
        tone: true,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },
    'birthday': {
      key: 'birthday',
      widget: 'compose-ai',
      info: {
        operation: 'birthday'
      },
      options: {
        customFields: [
          {label: 'Name', key: 'name'},
          { label: 'Age', key: 'age'},
        ],
        noHeader: true,
        hide: false,
        tone: true,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },
    'linkedinArticle': {
      key: 'linkedinArticle',
      widget: 'compose-ai',
      info: {
        operation: 'linkedinArticle'
      },
      options: {
        noHeader: true,
        hide: false,
        viewOnly: false,
        audience: false,
        tone: true,
        action: false,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },
    'commentToPost': {
      key: 'facebookPost',
      widget: 'compose-ai',
      info: {
        operation: 'facebookPost'
      },
      options: {
        noHeader: true,
        hide: false,
        viewOnly: false,
        audience: false,
        tone: true,
        action: false,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },
    'recommendationLetter': {
      key: 'facebookPost',
      widget: 'compose-ai',
      info: {
        operation: 'facebookPost'
      },
      options: {
        noHeader: true,
        hide: false,
        viewOnly: false,
        audience: false,
        tone: true,
        action: false,
        about: true,
        copy: true,
        showAdd: false,
        showGenerate: true,
      },
      callback: (info) => this.suggest(info)
    },
  }
  siteKey = environment.reCaptchaKey;
  emailIcon = emailIcon;
  form = new UntypedFormGroup({
    message: new UntypedFormControl('', Validators.minLength(10)),
    recaptcha: new UntypedFormControl('', Validators.required)
  });
  followup = '';
  @Input() generate: string = '';
  selectedGenerator = null;
  @Output() messageGenerated = new EventEmitter();

  constructor(private textAiService: TextAiService,
              private generalService: GeneralService,
              private reCaptchaV3Service: ReCaptchaV3Service) {

  }

  ngOnInit() {
    this.selectedGenerator = this.promptsMap[this.generate];
  }

  selected(event) {
    navigator.clipboard.writeText(event);
    this.generalService.notification('Follow up copied to clipboard.', 'COPY');
  }

  handleReset() {
    console.log('reset');
  }

  handleExpire() {
    console.log('handleExpire');
  }

  handleLoad() {
    console.log('handleLoad');
  }

  handleSuccess(ev) {
    console.log('handleLoad', ev);
  }

  suggest(info) {
    this.loading = true;
    return from(this.reCaptchaV3Service.executeAsPromise(this.siteKey, 'homepage', {
      useGlobalDomain: false
    })).pipe(switchMap((token)=> {
      info.token = token;
      info.meta = {token};
      debugger;
      return this.textAiService.publicGenerator(info).pipe(take(1));
    }))
  }

}
