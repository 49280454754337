import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit, Optional,
  Output,
  ViewChild
} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {GeneralService} from "@services/general.service";
import {Popover} from "@widgets/popover/popover.service";
import {TextAiService} from "@services/text-ai.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomService} from "@services/dom-service";
import {ControlValueAccessor, FormControl, FormGroup} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {OPERATION_METHOD} from "@const/operation-method.enums";
import {MatChipInputEvent} from "@angular/material/chips";
import closeIcon from '@iconify/icons-mdi-light/plus';
import {take} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import { AppUser } from '@app/models/app-user';

@Component({
  selector: 'app-template',
  template: `
    <div fxLayout="row" class="template-widget">
<!--      <app-template-select></app-template-select>-->
      <div [formGroup]="form" fxLayout='row' fxLayoutAlign="center stretch">
        <div class="app-container-m">
          <div #tmpHeader class="tmp-header" fxLayout="row">
            <h3 style="font-weight: 500;margin: 0;">EDIT TEMPLATE</h3>
            <button (click)="saveTemplate()" class="pull-r" mat-flat-button color="primary">SAVE</button>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex.xs="100" class="template-main" style="margin-right: 30px;">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                <div fxFlex="49" fxFlex.xs="100" class="fw m-b-20">
                  <div #templateTitle>Template Name</div>
                  <input formControlName="name"
                         class="fw toolbar-input toolbar-input-border fc fr"/>
                </div>
                <div fxFlex="49" fxFlex.xs="100" class="fw m-b-20">
                  <div>Shortcut</div>
                  <input formControlName="shortcut"
                         class="fw toolbar-input toolbar-input-border fc fr"/>
                </div>
              </div>
              <app-editor formControlName="content"></app-editor>
            </div>
            <div *ngIf="sideBarOn" fxFlex.xs="100"  fxFlex="30" class="tmp-sidebar" fxLayout="column">

              <div class="tmp-sidebar-color m-b-20 p-10">
                <div style="font-size: 1rem" class="m-b-5 bold">Created by</div>
                <div  class="m-b-20" fxLayout="row" fxLayoutAlign="left center">
                  <div class="tiny round m-r-20"><img class="fw" [src]="user?.user?.photoURL"/></div>
                  <div>
                    <div>{{user?.user?.displayName}}</div>
                    <div>{{user?.user?.email}}</div>
                  </div>
                </div>

                <div *ngIf="templateId !== 'new' && templateId" class="m-b-20" >
                  <div style="font-size: 1rem" class="m-b-5 bold">Created</div>
                  <div>{{template?.created | date:'medium'}}</div>
                </div>

                <div *ngIf="templateId !== 'new' && templateId" class="m-b-20" >
                  <div style="font-size: 1rem" class="m-b-5 bold">Last modified</div>
                  <div>{{template?.updated | date:'medium'}}</div>
                </div>
              </div>



              <div [formGroup]="form" class="tags tmp-sidebar-color p-10">
                <div class="bold">Tags (optional)</div>
                <mat-form-field style="background: white;" class="m-b-20 template-tag fw toolbar-input-border">
                  <!--            <mat-label>Favorite Fruits</mat-label>-->
                  <mat-chip-list  multiple formControlName="tags" #chipList aria-label="tag selection">
                    <mat-chip *ngFor="let tag of form.value.tags" [value]="tag" (removed)="remove(tag)">
                      {{tag}}
                      <button matChipRemove class="remove-chip-btn">
                        <ic-icon [icon]="closeIcon"></ic-icon>
                      </button>
                    </mat-chip>
                    <input
                      class="tag-input"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="add($event)"/>

                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
                      <mat-option *ngFor="let remoteTag of remoteTags" [value]="remoteTag">
                        {{remoteTag}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-chip-list>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .template-widget input{
      text-align: left;
    }
    :host {
      width: 100%;
      height: 100%;
    }

    .remove-chip-btn {
      font-size: 22px;
      color: white;
      margin-top: -4px;
      transform: rotate(45deg);
    }

    .tag-input {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .app-container-m {
      margin: 5px !important;
      padding: 15px !important;
    }

    .bold {
      font-weight: 500;
    }

    .tmp-sidebar {
      /*padding: 16px 0 16px 16px;*/
    }

    .tmp-sidebar-color {
      background: #8080801c;
    }

    .tmp-header {
      position: sticky;
      top: -1px;
      transition: .1s; /* ➜ the trick */
      background: white;
      z-index: 99999999;

      padding: 1em 0;
      padding-top: calc(1em + 1px); /* ➜ compensate for the trick */
    }

    .sticky-header {
      border-bottom: solid 1px rgba(128, 128, 128, 0.19);
    }

    .template-tag {
      padding: 0 5px;
    }

    ::ng-deep .template-tag .mat-form-field-infix {
      border: none !important;
    }

    ::ng-deep .template-tag .mat-form-field-underline {
      display: none;
    }

    ::ng-deep .template-tag .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  `]
})
export class TemplateComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChild('tmpHeader') tmpHeader: ElementRef<HTMLElement>;
  @ViewChild('templateTitle') templateTitle: ElementRef<HTMLElement>;
  @Input() templateId = null;
  @Input() sideBarOn = true;
  @Output() events = new EventEmitter();
  user: any = null;
  template = null;
  closeIcon = closeIcon;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  form = new FormGroup({
    content: new FormControl({value: '', disabled: false}),
    id: new FormControl(''),
    name: new FormControl(''),
    category: new FormControl(''),
    tags: new FormControl(''),
    shortcut:  new FormControl(''),
  });
  tags = new Set([]);
  observer: any;
  remoteTags = [];
  constructor(private authService: AuthService,
              private generalService: GeneralService,
              private popper: Popover,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private textAiService: TextAiService,
              private domService: DomService,
              @Optional() public dialogRef: MatDialogRef<TemplateComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    if(this.data) {
      this.sideBarOn = this.data.sideBarOn || this.sideBarOn;
      this.templateId = this.data.templateId || this.templateId;
    }
    this.authService.user$.subscribe((user: AppUser) => {
      this.user = user;
      if(this.user && this.user.options) {
        this.remoteTags = this.user && this.user.options.tags || [];
      }
    });
  }

  tagSelected({option}) {
    this.tags.add(option.value);
    const value = [...this.tags.values()];
    this.form.get('tags').patchValue(value as any);
  }

  ngOnInit(): void {
    if(this.templateId === 'new') {
      this.form.patchValue({
        id: '',
        shortcut: '',
        name: '',
        content: '',
        tags: []
      } as any);
      return;
    }
    this.textAiService.templatesCrud({
      method: OPERATION_METHOD.GET,
      templateId: this.templateId,
      id: this.templateId,
    })
      .pipe(take(1))
      .subscribe((template) => {
        this.template = template;
        this.form.patchValue({
          id: template.id,
          shortcut: template.shortcut,
          name: template.name,
          content: template.content,
          tags: template.tags
        });
      });
  }

  saveTemplate() {
    console.log('save', this.form.value);
    const template = this.form.value;
    const method = template.id ? OPERATION_METHOD.UPDATE : OPERATION_METHOD.SAVE;
    this.textAiService.templatesCrud({
      method,
      ...this.form.value
    })
      .pipe(take(1))
      .subscribe((res) => {
        this.snackBar.open(`Template ${template.name} saved.`, '',{duration: 3000});
        this.dialog.closeAll();
        this.events.emit({name: 'complete' });
      })
  }

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(([e]) => {
        console.log(e, e.intersectionRatio < 1)
        e.target.classList.toggle('sticky-header', e.intersectionRatio < 1);
      },
      {threshold: [1]}
    );

    this.observer.observe(this.tmpHeader.nativeElement);
  }

  add(event: MatChipInputEvent): void {
    if (event.value) {
      this.tags.add(event.value);
      event.chipInput!.clear();
    }
    this.form.get('tags').patchValue([...this.tags.values()] as any)
  }

  remove(tag: string): void {
    this.tags.delete(tag);
    this.form.get('tags').patchValue([...this.tags.values()] as any)
  }


  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  ngOnDestroy() {
    try {
      this.observer.unobserve();
    } catch (e) {

    }
  }


}
