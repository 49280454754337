import { Injectable, Injector } from '@angular/core';
import { Overlay, ConnectionPositionPair, PositionStrategy, OverlayConfig } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { PopoverRef, PopoverContent } from './popover-ref';
import { PopoverComponent } from './popover.component';

export type PopoverParams<T> = {
  width?: string | number;
  height?: string | number;
  origin: HTMLElement;
  content: PopoverContent;
  offsetX?: number
  data?: T;
  showOverlay?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class Popover {
  popoverRef;
  pops = [];
  constructor(private overlay: Overlay, private injector: Injector) { }

  close() {
   try {
     console.log('close pop');
     this.popoverRef.close();
   } catch (e) {
     console.error('close pop', e);
   }
  }
  open<T>({ origin, content, data, width, height, offsetX, pos, showOverlay }: any): PopoverRef<T> {
    console.log('offset', offsetX, width);
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height, offsetX }, pos, showOverlay));
    this.popoverRef = new PopoverRef<T>(overlayRef, content, data);
    const injector = this.createInjector(this.popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

    return this.popoverRef;
  }

  private getOverlayConfig({ origin, width, height, offsetX }, pos = [], showOverlay = false): OverlayConfig {
    const backdropClass = ['writi-root'];
    if(showOverlay) {
      backdropClass.push('popover-backdrop');
    }
    return new OverlayConfig({
      hasBackdrop: true,
      height,
      panelClass: 'writi-root',
      backdropClass,
      positionStrategy: this.getOverlayPosition(origin, offsetX, pos),
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  getOverlayPosition(origin: HTMLElement, offsetX, pos = [], offsetY = 0): PositionStrategy {
    const position = this.getPositions(pos);
    console.log('getOverlayPosition  ', position);
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withGrowAfterOpen()
      .withPositions(position)
      .withDefaultOffsetX(offsetX || 10)
      .withDefaultOffsetY(offsetY || 0)
      .withFlexibleDimensions(true)
      .withPush(false);
    return positionStrategy;
  }

  createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getPositions(pos = []): ConnectionPositionPair[] {
    return  pos && pos.length ? pos : [
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom'
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      },
    ]
  }

}
