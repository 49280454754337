import {
  AfterViewInit,
  Component,
  ElementRef, forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {tonesOptions} from "./tones.options";
import {GeneralService} from "@services/general.service";
import {CacheService} from "@services/cache.service";

@Component({
  selector: 'gpt3-tone-select',
  template: `
    <div  class="settings-wrapper flex-col" [style.max-width.px]="maxWidth">
        <div class="toolbar-input toolbar-input-border fc fr">
          <mat-select (openedChange)="onOpen()" (focus)="onOpen()" panelClass="writi-root" [formControl]="toneCtl" placeholder="Tone">
            <mat-option *ngFor="let tone of tones" [value]="tone.value">
              {{tone.label}}
            </mat-option>
          </mat-select>
        </div>
    </div>
  `,
  styles: [`
   :host {
     min-width: 120px;
     background: white;
   }
   :host .toolbar-input {
     width: 100%;
     min-width: 120px;
     height: 35px;
   }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Gpt3ToneComponent),
      multi: true,
    }
  ],
})
export class Gpt3ToneComponent implements OnDestroy, OnInit, AfterViewInit, ControlValueAccessor {
  panelOpenState = false;
  tones = tonesOptions;
  toneCtl = new UntypedFormControl('')
  @Input() maxWidth = 300;
  private onChange = (_: any) => null;
  private onTouched = () => null;

  constructor(private generalService: GeneralService, private cacheService: CacheService) {
  }

  onOpen() {
    this.generalService.addClassToOverlay();
  }

  ngOnInit(): void {
    this.toneCtl.valueChanges.subscribe((tone)=> {
      if(tone) {
        this.cacheService.save({key: 'tone', data: tone});
      }
      this.onChange(tone);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {
      this.panelOpenState = true;
    }, 500);
    const tone = this.cacheService.load('tone');
    if(tone) {
      this.toneCtl.patchValue(tone);
    }
  }

  ngOnDestroy(): void {
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(tone: any): void {
    this.toneCtl.patchValue(tone, {emitEvent: false});
  }

}
