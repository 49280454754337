import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import {BehaviorSubject, Observable, of} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {AngularFireRemoteConfig, filterFresh, scanToObject} from "@angular/fire/compat/remote-config";
import {first, map, tap} from "rxjs/operators";
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import {AuthService} from "./auth.service";
import {GeneralService} from "@services/general.service";
import {isWindowBlocked} from "@helper-functions/is-window-blocked";

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  customersCollection;
  productsCollection;
  productsMap = new Map();
  products = new BehaviorSubject(null);
  products$ = this.products.asObservable();
  singleProduct = new BehaviorSubject(null);
  singleProduct$ = this.singleProduct.asObservable();
  defaultProduct = new BehaviorSubject(null);
  defaultProduct$ = this.defaultProduct.asObservable();
  subscription = new BehaviorSubject(null);
  subscription$ = this.subscription.asObservable();
  constructor(private authService: AuthService,
              private generalService: GeneralService,
              private afs: AngularFirestore,
              private fns: AngularFireFunctions,
              remoteConfig: AngularFireRemoteConfig) {
    this.customersCollection = afs.collection( 'customers');
    this.productsCollection = afs.collection( 'products');
    remoteConfig.changes.pipe(
      filterFresh(172_800_000), // ensure we have values from at least 48 hours ago
      first(),
      tap((cof)=> {
        console.log('config',cof)
      }),
      map((conf: any)=> JSON.parse(conf._value)),
      // scanToObject when used this way is similar to defaults
      // but most importantly smart-casts remote config values and adds type safety
      // scanToObject({ singleProduct: 'price_1K81WlKZJnVxmzBVQ8M4LKsk' })
    ).subscribe((conf)=> {
      this.singleProduct.next(conf.singleProduct);
      this.initProducts();
    });
  }

  async initProducts() {
    const productsRef = await this.productsCollection.ref
      .where('active', '==', true)
      .get();
    const productRefArr = productsRef.docs;
   for(const productRef of productRefArr) {
     const product = productRef.data();
     const  productPrices = await this.getPrice(productRef);
     productPrices.forEach((productPrice)=> {
       productPrice.price = productPrice.unit_amount / 100;
       productPrice.priceLabel = new Intl.NumberFormat('en-US', {
         style: 'currency',
         currency: productPrice.currency,
       }).format(Number((productPrice.unit_amount / 100).toFixed(2)));
       this.productsMap.set(productPrice.priceId, {...product, ...productPrice});
     });
   }
   this.products.next(this.productsMap);

   this.defaultProduct.next(this.productsMap.get(this.singleProduct.getValue()));
  }

  async getPrice(productRef) {
    const product = await productRef.ref.collection('prices')
      .where('active', '==', true)
      .orderBy('unit_amount')
      .get();
    return product.docs.map( (p: QueryDocumentSnapshot) => Object.assign({priceId: p.id, ...p.data()}));
  }

  getCustomer(uid: string) {
    return this.customersCollection.doc(uid).get();
  }


  getClientReferenceId() {
    return (window as any).Rewardful && (window as any).Rewardful.referral || ('checkout_'+(new Date).getTime());
  }

  async remoteCheckout(product) {
    const selectedPrice = {
      price: product.priceId,
      quantity: 1,
    };
    const checkoutSession = {
      priceId: product.priceId,
      automatic_tax: false,
      tax_id_collection: false,
      collect_shipping_address: true,
      allow_promotion_codes: true,
      line_items: [selectedPrice],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        price: product.priceId,
      },
      clientReferenceId: this.getClientReferenceId()
    };
    const {url} = await this.generalService.createFnPostRequest('createPaymentSessionForCustomer', {info: checkoutSession}).toPromise();
    if (url) {
      const win = window.open(url, "_blank");
      if(isWindowBlocked(win)) {
        window.location.href = url;
      }
      // window.location.assign(url);
    }
  }

  async checkout(product) {
    const user = this.authService.User();
    const selectedPrice = {
      price: product.priceId,
      quantity: 1,
    };
    // For prices with metered billing we need to omit the quantity parameter.
    // For all other prices we set quantity to 1.
    const checkoutSession = {
      automatic_tax: false,
      tax_id_collection: false,
      collect_shipping_address: true,
      allow_promotion_codes: true,
      line_items: [selectedPrice],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        price: product.priceId,
      },
    };
    const docRef = await this.customersCollection
      .doc(user.id())
      .collection('checkout_sessions')
      .add(checkoutSession);
    return new Promise((resolve, reject)=> {
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap: any)=> {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and then inspect your function logs.
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          const win = window.open(url, "_blank");
          if(isWindowBlocked(win)) {
            window.location.href = url;
          }
          // window.location.assign(url);
        }
        return resolve(snap.data());
      });
    });
  }

  async billingPortal() {
    const data = await this.generalService.createFnGetRequest('getLinkToSubscription').toPromise();
    // const callable = this.fns.httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    // const data = await callable({}).toPromise();
    console.log('LINK', data);
    window.open(
      data['url'], "_blank");
    return true;
  }

}
