import {Injectable} from '@angular/core';
import {of} from "rxjs";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {AI_ACTION} from "@const/ai.enums";
import {GeneralService} from "@services/general.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {increment} from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class TextAiService {
  cachedResults = new Map();
  followUpCollection = this.afs.collection('followup-examples');
  configCollection = this.afs.collection('config');
  constructor(private fns: AngularFireFunctions, private generalService: GeneralService,  public afs: AngularFirestore) {
  }


  promptsCollection(queryFn) {
    return this.afs.collection('prompts', (ref)=> queryFn(ref));
  }

  async saveFollowUpExample(value: any) {
   const data = await this.followUpCollection.add(value);
   const countRed = await this.configCollection.doc('followup').set({
     count: increment(1)
   }, { merge: true });
   // @ts-ignore
    return this.configCollection.doc('followup').get().toPromise().then((res) => res.data().count);
  }

  getPrompts(uid: string) {
    return this.promptsCollection((ref)=> ref.where('uid', '==', uid)).get();
  }

  setResults(id, results, set = false) {
    if(set) {
      const lastCache = this.cachedResults.get(id);
      if(lastCache) {
        const newCache = {...lastCache, ...results};
        this.cachedResults.set(id, newCache);
      }
    } else {
      this.cachedResults.set(id, results);
    }
  }

  getResults(id) {
    return this.cachedResults.get(id);
  }

  deleteResults(id) {
    this.cachedResults.delete(id);
  }


  /**
   * EMAIL METHODS START
   */

  /**
   * Respond to thread
   * @param info
   * @param version
   */
  respondToThread(info, version = 1) {
    return this.generalService.functionRequest$('respondToThread', {
      info,
      version
    });
  }

  /**
   * Generate message by instructions.
   * @param info
   * @param options
   */
  generateMessageByInstructions(info, options: any = {}) {
    return this.generalService.functionRequest$('replyByMethod', {
      method: AI_ACTION.INSTRUCTION,
      info,
      options
    })
  }

  continueText(info, options: any = {}) {
    return this.generalService.functionRequest$('continue-text', {
      method: AI_ACTION.CONTINUE,
      info,
      options
    })
  }

  /**
   * Suggest follow up
   * @param info
   * @param options
   */
  suggestFollowUp(info, options = {}) {
    return this.generalService.functionRequest$('suggestFollowUp', {
      info
    });
  }

  /**
   * suggest subject lines.
   * @param info
   * @param options
   */
  suggestSubjectLine(info, options = {}) {
    return this.generalService.functionRequest$('suggestSubjectLine', {
      info
    });
  }

  /**
   * EMAIL METHODS END
   */


  /**
   * Linkedin Connect
   * @param info
   */
  suggestConnectionRequest(info: any) {
    return this.generalService.functionRequest$('suggestLinkedinConnectionRequest', {
      info,
    });
  }

  suggestRecommendationRequest(info: any) {
    return this.generalService.functionRequest$('suggestRecommendationRequest', {
      info,
    });
  }

  /**
   * Linkedin description
   * @param info
   * @param tone
   * @param type
   */
  suggestProfileDescription(info: any) {
    return this.generalService.functionRequest$('suggestProfileDescription', {
      info,
    });
  }

  /**
   * Social posts(linkedin, facebook)
   * @param info
   */
  suggestPosts(info) {
    console.log(`suggestLinkedinPost()`, info)
    return this.generalService.functionRequest$('suggestPosts', {
      info,
    });
  }

  suggestByTemplateSubject(info) {
    console.log(`suggestByTemplateSubject()`, info)
    return this.generalService.functionRequest$('suggestByTemplateSubject', {
      info,
    });
  }

  /**
   * Social comments(linkedin, facebook)
   * @param info
   */
  suggestCommentToPost(info: any,  options = {}) {
    return this.generalService.functionRequest$('suggestCommentToPost', {
      info
    });
  }

  /**
   * Social chat(linkedin, facebook)
   * @param tone
   * @param info
   */
  suggestMessageForChat(info) {
    return this.generalService.functionRequest$('suggestMessageForChat', {
      info
    });
  }

  /**
   * Social article(linkedin, facebook)
   * @param info
   * @param tone
   * @param option
   */
  suggestArticle(info: any,option = {}) {
    return this.generalService.functionRequest$('suggestArticle', {
      info
    });
  }

  suggestSubjectLinePublic(message, tone, options = {}, token = '') {
    return this.generalService.functionRequest$('suggestSubjectLinePublic', {
      tone,
      message,
      token
    });
  }

  publicGenerator(info: any) {
    return this.generalService.functionRequest$('publicGenerator', info);
  }

  suggestFollowUpPublic(message, tone, options = {}, token = '') {
    return this.generalService.functionRequest$('suggestFollowUpPublic', {
      tone,
      message,
      token
    });
  }

  suggestByDescriptionUpPublic(about, tone, options = {}, token = '') {
    return this.generalService.functionRequest$('suggestComposeByDescriptionPublic', {
      tone,
      about,
      token
    });
  }

  generateMessageByBulletPoints(message, tone, options: any = {}) {
    return this.generalService.functionRequest$('replyByMethod', {
      method: AI_ACTION.BULLET_POINT,
      tone,
      message
    })
  }

  suggestBlogBuilder(info) {
    return this.generalService.functionRequest$('suggestBlogBuilder', {info});
  }

  generateBlog(data) {
    return this.generalService.functionRequest$('contentByMethod', data);
  }

  translate(info) {
    return this.generalService.functionRequest$('translate', {
      info
    });
  }

  generateImages(info) {
    return this.generalService.functionRequest$('suggest-images', {info})
  }


  //entities crud
  blogsCrud(info) {
    return this.generalService.functionRequest$('blogsCrud', {
      info
    });
  }

  templatesCrud(info) {
    return this.generalService.functionRequest$('templatesCrud', {
      info
    });
  }

  downloadCsv(info) {
    return this.generalService.functionRequest$('downloadCsvFile', {
      info
    });
  }

  userAdminUpdate(info) {
    return this.generalService.functionRequest$('usersUpdate', {
      info
    });
  }

  keyPhrases(info) {
    return this.generalService.functionRequest$('keyPhrases', {
      info
    });
  }

  createAffiliate() {
    return this.generalService.functionRequest$('createAffiliate', {

    });
  }

  affiliateSSO() {
    return this.generalService.functionRequest$('affiliateSSO', {

    });
  }

}
