import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, of, Subject} from "rxjs";
import {filter, switchMap, take} from "rxjs/operators";
import {GeneralService} from "@services/general.service";


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig = new BehaviorSubject(null);
  config$ = this.appConfig.asObservable().pipe(
    filter((config) => config)
  );
  constructor (private injector: Injector, private generalService: GeneralService) {
    this.loadAppConfig();
  }

  loadAppConfig() {
    let http = this.injector.get(HttpClient);

    return http.get(`${this.generalService.fnEndpoint}/app-config`)
      .pipe(switchMap((config)=> {
        this.appConfig.next(config);
        return of(config);
      }),
       take(1),
      ).subscribe()
  }
}
