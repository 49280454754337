export const tonesOptions = [
  {label: "👤 Normal", value: ""},
  {label: "😈 Arrogant", value: "arrogant"},
  {label: "👧🏽 Childish", value: "childish"},
  {label: "🤡 Cheerful", value: "cheerful"},
  {label: "📢 Direct", value: "direct"},
  {label: "💞 Empathetic", value: "empathetic"},
  {label: "🥹 Emotional", value: "emotional"},
  {label: "💍 Engaging", value: "engaging"},
  {label: "🍾 Excited", value: "excited"},
  {label: "🤣 Funny", value: "funny"},
  {label: "😜 Foolish", value: "foolish"},
  {label: "🤵🏽 Formal", value: "formal"},
  {label: "⛹🏽 Go-getter", value: "go-getter"},
  {label: "😠 Grumpy", value: "grumpy"},
  {label: "😃 Happy", value: "happy"},
  {label: "📰 Informative", value: "informative"},
  {label: "😵‍💫 Irrational", value: "irrational"},
  {label: "🐯 Jack-of-all-trades", value: "jack-of-all-trades"},
  {label: "🍄 Know-it-all", value: "know-it-all"},
  {label: "🤓 Nerdy", value: "nerdy"},
  {label: "😩 Needy", value: "needy"},
  {label: "👩🏻‍ Professional", value: "professional"},
  {label: "🎸 Rock-star", value: "rock-star"},
  {label: "😼 Sarcastic", value: "sarcastic"},
  {label: "😱 Shocked", value: "shocked"},
  {label: "🤔 Skeptical", value: "skeptical"},
  {label: "🤪 Unprofessional", value: "unprofessional"},
];
