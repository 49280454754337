import {Injector, isDevMode, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {createCustomElement} from '@angular/elements';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '@environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import {provideFunctions, getFunctions, connectFunctionsEmulator} from '@angular/fire/functions';
import {AngularFireAuthGuardModule} from "@angular/fire/compat/auth-guard";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireAuthModule, SETTINGS} from "@angular/fire/compat/auth";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AngularFireFunctionsModule, REGION} from "@angular/fire/compat/functions";
import {AngularFireRemoteConfigModule, DEFAULTS} from "@angular/fire/compat/remote-config";
import {HttpClientModule} from "@angular/common/http";
import {SubjectLineWidget} from "@widgets/subject-line-widget/subject-line.widget";
import {SubjectLineWidgetModule} from "@widgets/subject-line-widget/subject-line.widget.module";
import {FollowUpWidgetModule} from "@widgets/follow-up-widget/follow-up.widget.module";
import {FollowUpWidget} from "@widgets/follow-up-widget/follow-up.widget";
import {MultiWidgetModule} from "@widgets/multi-widget/multi.widget.module";
import {MultiWidget} from "@widgets/multi-widget/multi.widget";
import {LinkedinWidget} from "@widgets/linkedin-widget/linkedin.widget";
import {LinkedinWidgetModule} from "@widgets/linkedin-widget/linkedin.widget.module";

@NgModule({
  declarations: [
  ],
  imports: [
    SubjectLineWidgetModule,
    FollowUpWidgetModule,
    MultiWidgetModule,

    BrowserModule,
    AngularFireAuthGuardModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => {
      console.log('HELLO PROVIDE f/unction')
      if (environment.useEmulators || true) {
        console.log('emulator')
        const firefunctions = getFunctions();
        // connectFunctionsEmulator(firefunctions, 'localhost', 5001); // <--- FireFunctions Port
        return firefunctions;
      } else {
        return getFunctions();
      }
    }),
    AngularFireFunctionsModule,
    AngularFireRemoteConfigModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LinkedinWidgetModule
  ],
  providers: [
    { provide: REGION, useValue: 'us-central1' },
    // { provide: DEFAULTS, useValue: { enableAwesome: true } },
    {
      provide: SETTINGS,
      useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}
    }
  ],
})
export class AppWebElementsModule {
  constructor(private injector: Injector) {

    const subjectLineElem =  createCustomElement(SubjectLineWidget, { injector: this.injector });
    if (!customElements.get('subject-line-widget')) {
      customElements.define('subject-line-widget', subjectLineElem);
    }

    const multiWidget = createCustomElement(LinkedinWidget, { injector: this.injector });
    if (!customElements.get('generator-wise')) {
      customElements.define('generator-wise', multiWidget);
    }


    const followUpElem =  createCustomElement(FollowUpWidget, { injector: this.injector });
    if (!customElements.get('follow-up-widget')) {
      customElements.define('follow-up-widget', followUpElem);
    }

    const multiWidgetElem =  createCustomElement(MultiWidget, { injector: this.injector });
    if (!customElements.get('multi-widget')) {
      customElements.define('multi-widget', multiWidgetElem);
    }
  }
  ngDoBootstrap(): void {}
}

console.log('web elements 1.3')
