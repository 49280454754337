
export async function asyncWait(timeout: number = 1000) {
  return new Promise((resolve, reject)=> setTimeout(() => resolve(true), timeout))
}

export async function retryFindElement(selector: string, timeout: number = 1000, retry: number = 2) {
  while (!document.querySelector(selector) && retry > 0) {
    await asyncWait(timeout);
    retry--;
  }
  return document.querySelector(selector);
}


export async function retryFindElements(selector: string, timeout: number = 1000, retry: number = 2) {
  while (!document.querySelectorAll(selector).length && retry > 0) {
    await asyncWait(timeout);
    retry--;
  }
  return document.querySelectorAll(selector);
}
