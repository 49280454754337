export function recordForLiquid(record) {
  const liquidRecord = record && record.info || {};
  if(record && record.info) {
    for(const field in record.info) {
      if(field && record.__mask && record.__mask[field]) {
        liquidRecord[`_${field}`] = record.__mask[field];
      }
    }
  }

  return liquidRecord;
}


export function getAllVariables(str) {
  let found = [],          // an array to collect the strings that are found
    rxp = /{{([^}]+)}}/g,
    curMatch;

  while( curMatch = rxp.exec( str ) ) {
    if(curMatch && curMatch[0]) {
      found.push( curMatch[0] );
    }
  }
  return found;
}

export function replaceAllVariableDefaultValue(str) {
  let msg = str;
  let variables = getAllVariables(str);
  variables.forEach(variable => {
    const splits = variable.replace('{{', '').replace('}}', '').split('|');
    if(splits && splits[0]) {
      const key = splits[0].trim();
      msg = msg.replace(variable, `{{${key}}}`)
    }
  });
  return msg;
}

export function plainVariableToProsVariable(str) {
  let msg = str;
  let variables = getAllVariables(str);
  variables.forEach(variable => {
    const splits = variable.replace('{{', '').replace('}}', '').split('|');
    if(splits && splits[0]) {
      const key = splits[0].trim();
      msg = msg.replace(variable, `<div class='preview-variable' data-key="${key}">${key}</div>`);
    }
  });
  return msg;

}
