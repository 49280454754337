import {DAY_IN_MILLISECONDS} from "@const/time.enums";
import {USER_STATUS} from "@const/global.enums";

export class AppUser {
  options: any = {};
  affiliate: any = {};
  firstLogin: boolean;
  free: boolean;
  trial: boolean;
  model: string = 'user';
  uid: string = null;
  isInTrial: boolean = true;
  trialExpiredDate: number = null;
  isTrialExpired: boolean = true;
  isLoggedIn = false;
  plan: string = null;
  planExpire: number = null;
  subscription: any = null;
  isPlanActive: boolean = false;
  planExpired: number = null;
  planName: string = '';
  renew: boolean = false;
  isSubscribed = false;
  creditUsed = 0;
  creditLeft = 0;
  quotaUsed = 0;
  usedPercentage = 0;
  usedTitle = '';
  firstName = '';
  priceId = '';
  firstVideo = true;
  profileImg = '';
  constructor(private user) {
    if(user) {
      this.isLoggedIn = true;
      this.init();
    }
  }

  getFeatures() {
    return this.options && this.options.features || {};
  }

  init() {
    // this._isInTrial();
    this._trialExpiredDate();
    this._isTrialExpired();
    this._initPlan();
    this.isSubscribed = (this.isInTrial && !this.isTrialExpired) || this.isPlanActive;
    this.uid = this.user && this.user.uid;
    const displayName = (this.user && this.user.displayName || '').split(' ');
    this.firstName = displayName && displayName[0] || '';
  }

  setSubscription(subscription, customer) {
    this.options = customer && customer.options;
    this.trial = customer.status == USER_STATUS.TRIAL;
    this.isInTrial = this.trial;
    this.subscription = subscription;
      this.planName = subscription && subscription.name || 'Trial';
      this.planExpired = customer && customer.current_period_end && customer.current_period_end.toMillis();
      this.renew = subscription && !subscription.cancel_at_period_end;
      this.isPlanActive = this.planExpired >= Date.now();
    if(customer) {
      this.affiliate = customer && customer.affiliate || {};
      this.firstVideo = customer.firstVideo;
      this.isSubscribed = customer.status == USER_STATUS.SUBSCRIBED;
      this.firstLogin = customer.firstLogin
      this.free = !!customer.free;
      this.creditLeft = customer.quotaCharsLeft;
      this.creditUsed = customer.quotaCharsUsed;
      const total = (this.creditUsed + this.creditLeft);
      this.usedPercentage = parseInt(((this.creditUsed / total) * 100).toString());
      this.usedTitle = `${this.creditUsed}/${total}`;
      this.priceId = customer.priceId;
      this.profileImg = customer.photo || 'https://writi.io/wp-content/uploads/writi-assets/profile/avatar.jpeg'
    }
    this.init();
  }

  updateQuota(quotaCharsLeft, total) {
    this.creditUsed = total - quotaCharsLeft;
    this.creditLeft = quotaCharsLeft;
    this.usedPercentage = parseInt(((this.creditUsed / total) * 100).toString());
  }

  isCreditLeft() {
    return this.creditLeft > 0;
  }

  id() {
    return this.uid;
  }

  get(k, df = '') {
    return this.user && this.user[k] && this.user[k] || df;
  }

  _isInTrial() {
    this.isInTrial = this.user && this.user.createdAt && this.user && !this.user.subscription && !this.user.free;
    this.isInTrial = this.trial;
  }

  _trialExpiredDate() {
    this.trialExpiredDate = parseInt(this.user.createdAt) + (DAY_IN_MILLISECONDS * 3);
    return this.planExpired;
  }



  _isTrialExpired() {
    this.isTrialExpired = this.planExpired < Date.now();
  }

  _initPlan() {
    this.plan = this.user.stripeRole;
  }

  _planExpiration() {
    this.planExpire = this.user.plan;
  }
}
