import {environment} from "@environment";

export const ASSETS_URL = environment.ASSETS_URL;



export const enum USER_STATUS {
  NONE,
  TRIAL,
  SUBSCRIBED,
  UNSUBSCRIBED,
  FREE
}
