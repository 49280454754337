import {ChangeDetectorRef, Directive, ElementRef, forwardRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {fromEvent, Subscription} from "rxjs";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
@Directive({
  selector: '[bulletpoint-area]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BulletpointsAreaDirective),
      multi: true,
    },
  ],
})

export class BulletpointsAreaDirective implements OnInit, OnDestroy, ControlValueAccessor {
  _activeBullets = true;
  bulletPrefix = '\u2022 ';
  onChange = (v)=> {};
  subscription1: Subscription;
  subscription2: Subscription;
  @Input() set activeBullets(activeBullets) {
    this._activeBullets = activeBullets;
    this.updateHandler();
  };
  get activeBullets() {
    return this._activeBullets;
  }

  constructor(private el: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    this.subscription1 = fromEvent(this.el.nativeElement, 'keyup', (t)=> this.handleInput(t)).subscribe()

  }

  ngOnInit(): void {

    }

  updateHandler() {
    const v = this.el.nativeElement.value || '';
    const nv = this.activeBullets ? this.convertToBullets(v) : this.convertToText(v);
    this.renderer.setProperty(this.el.nativeElement, 'value', nv);
    this.onChange(nv);
  }

  convertToBullets(value) {
   const splits = value.split('\n').filter((l)=> l);
   for(let i = 0; i < splits.length; i++) {
     const split = splits[i];
     if(!split.startsWith(this.bulletPrefix)) {
       splits[i] = `${this.bulletPrefix}${split}`;
     }
   }
   return splits.join('\n');
  }

  convertToText(value) {
    return value.replace(new RegExp(this.bulletPrefix,"g"),'');
  }

  handleInput = (event) => {
    console.log('handle input');
    const { keyCode, target } = event;
    const { selectionStart, value } = target;
    if(this.activeBullets) {
      const bullet = "\u2022";
      const bulletWithSpace = `${bullet} `;

      if (keyCode === 13) {
        const nv = [...value]
          .map((c, i) => i === selectionStart - 1
            ? `\n${bulletWithSpace}`
            : c
          )
          .join('');
        this.renderer.setProperty(this.el.nativeElement, 'value', nv);
        this.onChange(nv);
        // target.value = nv;

        target.selectionStart = selectionStart+bulletWithSpace.length;
        target.selectionEnd = selectionStart+bulletWithSpace.length;
      }

      if (value[0] !== bullet) {
        const nv = `${bulletWithSpace}${value}`;
        this.renderer.setProperty(this.el.nativeElement, 'value', nv);
        this.onChange(nv);
        // target.value = nv;
      } else {
        this.onChange(value);
      }
    } else {
      // this.renderer.setProperty(this.el.nativeElement, 'value', value);
      this.onChange(value);
    }
  }

  ngOnDestroy(): void {
    try {
      this.subscription1.unsubscribe();
      this.subscription2.unsubscribe();
    } catch (e) {
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: string): void {
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
  }
}
