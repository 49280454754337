export function isWindowBlocked(popupWindow) {
  if (popupWindow) {
    if (/chrome/.test(navigator.userAgent.toLowerCase())) {
      try {
        popupWindow.focus();
      } catch (e) {
        return true;
      }
    } else {
      popupWindow.onload = function() {
        return !(popupWindow.innerHeight > 0);
      };
    }
  } else {
    return true;
  }
  return false;
}
