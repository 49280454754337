import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from "@visurel/iconify-angular";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {LangSelectComponent} from "./lang-select.component";


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        IconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
  declarations: [
      LangSelectComponent,
  ],
  exports: [
      LangSelectComponent,
  ]
})
export class LangSelectModule {
}
